import React from 'react';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';

import IconUI from '../IconUI/IconUI';

import useStylesCustomInputMask from './CustomInputMaskStyles';

const CustomInputMask = function ({
  className = '',
  control,
  error = false,
  name = '',
  type = '',
}) {
  const classes = useStylesCustomInputMask();

  return (
    <div className={classes.custom_input_mask__wrap}>
      <Controller
        name={name}
        isClearable
        control={control}
        render={({ field }) => {
          return (
            <InputMask
              {...field}
              className={`${classes.custom_input_mask} ${error ? 'error' : ''} ${className}`}
              mask="+7 999 999 99 99"
              placeholder="+7"
              maskChar="_" // пустая строка или null что бы не было никаких знаков заполнения
              type={type}
            />
          );
        }}
      />

      {error
        && (
          <div className={classes.custom_input_mask__wrap_icon}>
            <IconUI name="icon_alert"/>
          </div>
        )}
    </div>
  );
};

export default CustomInputMask;
