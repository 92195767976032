import React, {useState, useEffect} from 'react';
import StickyBox from 'react-sticky-box';
import {connect} from 'react-redux';
import {Route, Routes, useLocation, Outlet} from 'react-router-dom';

import Section from '../components/Section/Section';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import AnimatedElem from '../components/AnimatedElem/AnimatedElem';
import {getRecaptchaAction, modalAction} from '../store/actions/commonActions';
import ButtonUI from '../components/UI/ButtonUI/ButtonUI';

import {recaptchaSiteKey} from '../store/selectors/commonSelectors';

import MainSection from '../components/MainSection/MainSection';
import SupportSection from '../components/SupportSection/SupportSection';
import DevelopmentSection from '../components/DevelopmentSection/DevelopmentSection';
import BitrixSection from '../components/BitrixSection/BitrixSection';
import NotFoundSection from '../components/NotFoundSection/NotFoundSection';
import Preloader from '../components/Preloader/Preloader';

import useStylesMainContainer from '../containers/MainContainer/MainContainerStyles';

import useStylesLayout from './layoutStyles';


const Layout = ({
  children,
  modalAction = () => {},
  getRecaptchaAction = () => {},
  recaptchaSiteKey,
}) => {
  const classes = useStylesLayout();
  const classesMainContainer = useStylesMainContainer();
  const location = useLocation();

  const [scrollBody, setScrollBody] = useState(document.documentElement.scrollTop); // получаем значение скролла у body
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    getRecaptchaAction();
  }, [getRecaptchaAction]);

  useEffect(() => {
    if (recaptchaSiteKey) {
      createReCaptcha(recaptchaSiteKey);
    }
  }, [recaptchaSiteKey]);

  const createReCaptcha = (siteKey) => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  };

  useEffect(() => {
    // получаем доступ в тегу body и его проскроленному значению
    const handleScrollBody = () => {
      setScrollBody(document.documentElement.scrollTop);
      if (document.documentElement.scrollTop > 1) setIsScrolled(true);
    };
    window.addEventListener('scroll', handleScrollBody);
    return () => {
      window.removeEventListener('scroll', handleScrollBody);
    };
  }, [scrollBody]);

  useEffect(() => {
    if (document.documentElement.scrollTop > 1) {
      setIsScrolled(false);
      setTimeout(() => {
        setIsScrolled(true);
      }, 0);
    } else setIsScrolled(false);
  }, [location.pathname]);

  return (
    <div className={classes.layout}>
      <StickyBox offsetTop={0} offsetBottom={0} className={classes.layout__sticky_box}>
        <AnimatedElem notScroll animate="fade_in_down_small" delay="2400">
          <Section customClass={`${classes.layout__wrap_header} ${scrollBody > 1 ? 'show_header' : ''}`}>
            <Header/>
          </Section>
        </AnimatedElem>
      </StickyBox>

      {/*<div className={classes.layout__content}>*/}
      {/*{children}*/}
      {/*<div className={classesMainContainer.main_container}>*/}
      {/*<Parallax*/}
      {/*  speed={-80}*/}
      {/*  rootMargin={{ top: 170, right: 100, bottom: 100, left: 100 }}*/}
      {/*>*/}
      <Routes>
        <Route element={
          <>
            <div className={classes.layout__content}>
              <div className={classesMainContainer.main_container}>
                <Section>
                  <Outlet/>
                </Section>
              </div>
            </div>
            {
              location.pathname !== '/' &&
              <StickyBox offsetBottom={0} offsetTop={0} bottom={true} className={`${classes.layout__sticky_box_for_btn} ${(isScrolled) ? 'show_big_btn' : ''}`}>
                <Section>
                  <div
                    className={`${classes.layout__btn} ${(isScrolled) ? 'long_big_btn' : ''}`}
                    onClick={() => modalAction('discuss_project', true)}
                  >
                    <ButtonUI
                      text="Обсудить проект"
                      typeBtn="big"
                    />
                  </div>
                </Section>
              </StickyBox>
            }
            <Section>
              <Footer/>
            </Section>
            <Preloader />
          </>
        }>
          <Route exact path="/" element={<MainSection/>}/>
          <Route exact path="/support" element={
            <SupportSection modalAction={() => {
              modalAction('discuss_project', true);
            }} isScrolledBody={isScrolled}/>
          }/>
          <Route exact path="/development" element={
            <DevelopmentSection modalAction={() => {
              modalAction('discuss_project', true);
            }} isScrolledBody={isScrolled}/>
          }/>
          <Route exact path="/bitrix" element={
            <BitrixSection modalAction={() => {
              modalAction('discuss_project', true);
            }} isScrolledBody={isScrolled}/>
          }/>
        </Route>
        }
        <Route path="*" element={<NotFoundSection/>}/>
      </Routes>
      {/* </Parallax> */}

      {/*<div className={classes.main_container__wrap}>*/}
      {/*  <Section id="scroll-to-first-section">*/}
      {/*    <SupportSection />*/}
      {/*  </Section>*/}

      {/*  <Section id="scroll-to-second-section">*/}
      {/*    <DevelopmentSection />*/}
      {/*  </Section>*/}

      {/*  <Section id="scroll-to-third-section">*/}
      {/*    <BitrixSection />*/}
      {/*  </Section>*/}
      {/*</div>*/}

      {/*</div>*/}
      {/*</div>*/}
      {/*{*/}
      {/*  location.pathname !== '/' &&*/}
      {/*  <StickyBox offsetBottom={0} offsetTop={0} bottom={true} className={`${classes.layout__sticky_box_for_btn} ${(isScrolled) ? 'show_big_btn' : ''}`}>*/}
      {/*    <Section>*/}
      {/*      <div*/}
      {/*        className={`${classes.layout__btn} ${(isScrolled) ? 'long_big_btn' : ''}`}*/}
      {/*        onClick={() => modalAction('discuss_project', true)}*/}
      {/*      >*/}
      {/*        <ButtonUI*/}
      {/*          text="Обсудить проект"*/}
      {/*          typeBtn="big"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </Section>*/}
      {/*  </StickyBox>*/}
      {/*}*/}
      {/*<Section>*/}
      {/*  <Footer />*/}
      {/*</Section>*/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  recaptchaSiteKey: recaptchaSiteKey(state),
});

const mapDispatchToProps = (dispatch) => ({
  modalAction: (bool, typeModal) => dispatch(modalAction(bool, typeModal)),
  getRecaptchaAction: () => dispatch(getRecaptchaAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
