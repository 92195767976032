import React from 'react';

import ButtonUI from '../UI/ButtonUI/ButtonUI';

import useStylesHorizontalCard from './HorizontalCardStyles';

const HorizontalCard = ({
  data = {},
  num = 0,
}) => {
  const classes = useStylesHorizontalCard();

  return (
    <div className={`${classes.horizontal_card}`}>
      <div className={classes.horizontal_card_top}>
        <div className={classes.horizontal_card_title}>
          {data?.title}
        </div>
      </div>

      <div className={classes.horizontal_card_wrap_btn}>
        <ButtonUI
          typeBtn="mini"
          text={`0${num}`}
        />
      </div>
    </div>
  );
};

export default HorizontalCard;
