import { makeStyles } from '@mui/styles';

const useStylesListCard = makeStyles((theme) => ({
  list_card: {
    borderTop: `1px solid ${theme.palette.black_80}`,
    padding: '24px 0',
    [theme.breakpoints.up('bp_560')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('bp_1900')]: {
      padding: '32px 0',
    },
  },

  list_card_title: {
    ...theme.typography.P2_M_900,
    marginBottom: 8,
    [theme.breakpoints.up('bp_560')]: {
      marginBottom: 0,
      width: '50%',
      paddingRight: 20,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.P2_M_1900,
    },
  },

  list_card__items: {
    ...theme.typography.P2_R_900,
    color: theme.palette.black_60,
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
    },
    [theme.breakpoints.up('bp_768')]: {
      paddingLeft: 24,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.P2_M_1900,
    },
  },
  
}));

export default useStylesListCard;
