import React from 'react';

import useStylesNums from './NumsStyles';

const Nums = ({
  nums = ['', ''],
}) => {
  const classes = useStylesNums();

  return (
    <div className={classes.nums}>
      <div className={classes.nums__first}>
        {nums?.[0]}
      </div>
      <div className={classes.nums__line}>
        ~
      </div>
      <div className={classes.nums__second}>
        {nums?.[1]}
      </div>
    </div>
  );
};

export default Nums;
