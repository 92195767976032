import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet';

// import { dataThirdSectionBigCards } from '../../helpers/data-helper';
// import BigCardBlock from '../BigCardBlock/BigCardBlock';
// import Headers from '../Headers/Headers';

import ButtonUI from '../UI/ButtonUI/ButtonUI';
import Section from '../Section/Section';

import useStylesLayout from '../../layout/layoutStyles';

import useStylesMainContainer from '../../containers/MainContainer/MainContainerStyles';

import useStylesNotFoundSection from './NotFoundSectionStyles';

const NotFoundSection = () => {
  const classes = useStylesNotFoundSection();
  const classesLayout = useStylesLayout();
  const classesMainContainer = useStylesMainContainer();
  const navigate = useNavigate();

  return (
    <>
      {/*<Helmet>*/}
      {/*  <title>Техподдержка сайтов на Битрикс и Битрикс 24 - БюроБюро</title>*/}
      {/*  <meta name="description" content="БюроБюро занимается техподдержкой сайтов на Битрикс и Битрикс 24, а так же обеспечивает их производительность и отказоустойчивость." />*/}
      {/*  <meta name="keywords" content="техподдержка Битрикс, поддержка сайтов битрикс" />*/}
      {/*</Helmet>*/}
      <div className={classesLayout.layout__content}>
        <div className={classesMainContainer.main_container}>
          <Section>
            <div className={classes.not_found_section}>
              <div className={classes.animation__container}>
                <div className={classes.head_anim__symbol}>
                  <div className={classes.head_anim__mask}></div>
                </div>
                <div className={classes.head_anim__mask_border}></div>
              </div>
              <div className={classes.not_found_num}>404</div>
              <div className={classes.not_found_text}>Такой страницы не существует</div>

              <div
                className={`${classes.return__btn}`}
              >
                <ButtonUI
                  text='Вернуться на главную'
                  typeBtn='mini'
                  onClick={() => {navigate('/');}}
                />
              </div>
            </div>
          </Section>
        </div>
      </div>
    </>
  );
};

export default NotFoundSection;
