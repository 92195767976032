import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Routes, Route} from 'react-router-dom';

import Section from '../../components/Section/Section';
import MainSection from '../../components/MainSection/MainSection';
import SupportSection from '../../components/SupportSection/SupportSection';
import DevelopmentSection from '../../components/DevelopmentSection/DevelopmentSection';
import BitrixSection from '../../components/BitrixSection/BitrixSection';
import {modalAction} from '../../store/actions/commonActions';

import NotFoundSection from '../../components/NotFoundSection/NotFoundSection';

import useStylesMainContainer from './MainContainerStyles';

const MainContainer = ({modalAction}) => {
  const classes = useStylesMainContainer();

  const [scrollBody, setScrollBody] = useState(document.documentElement.scrollTop); // получаем значение скролла у body
  const [isScrolledBody, setIsScrolledBody] = useState(false); // получаем значение скролла у body

  useEffect(() => {
    // получаем доступ в тегу body и его проскроленному значению
    const handleScrollBody = () => {
      setScrollBody(document.documentElement.scrollTop);
      if (document.documentElement.scrollTop > 1) setIsScrolledBody(true);
    };
    window.addEventListener('scroll', handleScrollBody);

    return () => {
      window.removeEventListener('scroll', handleScrollBody);
    };
  }, [scrollBody]);

  return (
    <>
      <div className={classes.main_container}>
        {/*<Parallax*/}
        {/*  speed={-80}*/}
        {/*  rootMargin={{ top: 170, right: 100, bottom: 100, left: 100 }}*/}
        {/*>*/}
        <Section>
          <Routes>
            <Route exact path="/" element={<MainSection/>}/>
            <Route exact path="/support" element={
              <SupportSection modalAction={() => {
                modalAction('discuss_project', true);
              }} isScrolledBody={isScrolledBody}/>
            }/>
            <Route exact path="/development" element={
              <DevelopmentSection modalAction={() => {
                modalAction('discuss_project', true);
              }} isScrolledBody={isScrolledBody}/>
            }/>
            <Route exact path="/bitrix" element={
              <BitrixSection modalAction={() => {
                modalAction('discuss_project', true);
              }} isScrolledBody={isScrolledBody}/>
            }/>
            <Route path='*' element={<NotFoundSection />}/>
          </Routes>
        </Section>
        {/* </Parallax> */}

        {/*<div className={classes.main_container__wrap}>*/}
        {/*  <Section id="scroll-to-first-section">*/}
        {/*    <SupportSection />*/}
        {/*  </Section>*/}

        {/*  <Section id="scroll-to-second-section">*/}
        {/*    <DevelopmentSection />*/}
        {/*  </Section>*/}

        {/*  <Section id="scroll-to-third-section">*/}
        {/*    <BitrixSection />*/}
        {/*  </Section>*/}
        {/*</div>*/}

      </div>
    </>
  );
};


const mapDispatchToProps = (dispatch) => ({
  modalAction: (bool, typeModal) => dispatch(modalAction(bool, typeModal)),
});

export default connect(null, mapDispatchToProps)(MainContainer);
