/* eslint-disable max-len */
export const dataHorizontalCards = { 
  texts: { 
    title: 'Гибкий пакет часов',
    description: 'В рамках технической поддержки мы предоставляем фиксированное количество часов работы наших сотрудников. С его помощью вы можете расширить команду системных администраторов, отслеживающих состояние вашего проекта, или подключить к работе специалистов дополнительных профилей.',
  },
  cards: [
    { title: 'Нагрузочное тестирование' },
    { title: 'Внедрение системы логирования' },
    { title: 'Консультации по CI/CD и внедрение непрерывных интеграции и развертывания' },
    { title: 'Консультации по IaC и внедрение подхода инфраструктура как код' },
    { title: 'Аудит оркестрации Kubernetes' },
  ],
};

export const dataHorizontalCardsSecond = { 
  texts: { 
    title: 'Расширение возможностей',
    description: 'Техническая поддержка – необходимый минимум для обеспечения бесперебойной работы проекта. Если вам нужен максимум – наши специалисты готовы вам помочь. ',
  },
  cards: [
    { title: 'Сократить время реагирования до 5 минут' },
    { title: 'Администрировать ваши базы данных' },
    { title: 'Осуществлять непрерывные процессы интеграции и развертывания (CI/CD)' },
    { title: 'Проконсультировать по DevOps' },
    { title: 'Вести документацию по проекту' },
  ],
};

export const dataListCards = [
  {
    title: 'Мониторинг и визуализация',
    items: ['Prometheus', 'Grafana', 'Victoria Metrics', 'Zabbix', 'TICK Stack', 'Nagios', 'Icinga', 'DataDog', 'NewRelic'],
  },
  {
    title: 'Управление инцидентами',
    items: ['PagerDuty', 'Grafana OnCall'],
  },
  {
    title: 'Логирование, отслеживание ошибок',
    items: ['ELK', 'Graylog', 'Grafana Loki', 'Sentry', 'EFK'],
  },
  {
    title: 'Трассировка',
    items: ['Jaeger', 'Zipkin Web', 'ingress и серверы приложений Nginx', 'Envoy', 'Linkerd', 'Traefik', 'Apache', 'HAProxy', 'Jetty', 'Tomcat', 'NodeJS'],
  },
  {
    title: 'Языки программирования',
    items: ['Python', 'JavaScript', 'TypeScript', 'Go', 'Java', 'PHP', 'Ruby', 'Erlang'],
  },
  {
    title: 'Облачные платформы и сервисы',
    items: ['Яндекс.Облако', 'Selectel', 'SberCloud', 'Amazon AWS', 'Google Cloud Platform', 'Microsoft Azure', 'Rackspace', 'Alibaba Cloud'],
  },
  {
    title: 'Контейнеризация',
    items: ['Docker', 'CRI-O', 'LXC', 'LXD'],
  },
  {
    title: 'Оркестрация',
    items: ['Kubernetes', 'Docker Swarm', 'RedHat OpenShift', 'Nomad', 'Mesos/Marathon'],
  },
  {
    title: 'CI/CD автоматизация',
    items: ['Jenkins', 'Gitlab CI', 'ArgoCD', 'Bitbucket Pipelines', 'TeamCity', 'CircleCI', 'Travis CI', 'GoCD', 'Spinnaker'],
  },
  {
    title: 'AWS CodePipeline, AWS CodeDeploy, AWS CodeCommit, Google CloudBuild, Spinnaker',
    items: ['Яндекс.Облако', 'Selectel', 'SberCloud RDS', 'AWS RDS и другие БД', 'Google Cloud SQL и другие БД', 'Firebase', 'MongoDB Atlas.'],
  },
  {
    title: 'Системы виртуализации',
    items: ['Hyper-V', 'VMWare (ESXi)', 'KVM (Libvirt и Proxmox)', 'Xen'],
  },
];

export const dataServicesCards = [
  { title: 'За сервисы отвечает отдел маркетинга, его KPI зависят от работоспособности систем, а своих технических сотрудников нет.' },
  { title: 'Отдел IT компании отвечает за другие процессы, нет профильных специалистов, нет возможности работать круглосуточно.' },
  { title: 'Свой специалист не несет достаточной ответственности за потери бизнеса, связанные с даунтаймами. Максимум, что ему грозит — увольнение.' },
  { title: 'Проект находится на начальном этапе, его необходимо как можно быстрее запустить, а своя инфраструктура еще не готова.' },
];

export const dataManyServicesCards = [
  { title: 'Экосистема SRE описывает проект, работающий в соответствии с требованиями бизнеса (SLA, RTO, RPO)' },
  { title: 'SRE-инженер не только фиксирует ошибки, но и своевременно их устраняет' },
  { title: 'Обеспечивается актуальность резервных копий' },
  { title: 'Инцидент-менеджмент алертов не допускает повторения одних и тех же проблем' },
  { title: 'Легкая адаптация к масштабированию и росту нагрузки' },
  { title: 'Деплои не приводят к падению системы и длительным даунтаймам' },
  { title: 'Вся инфраструктура задокументирована, что обеспечивает быстрое выявление и решение проблем' },
];

export const dataFirstSectionBigCards = {
  title: 'Как мы работаем',
  items: [
    { 
      title: 'Живая поддержка',
      description: 'Наши специалисты готовы ответить на ваши вопросы в чате в любое время. Никаких роботов – только живое общение с человеком. Решим все ваши проблемы!',
      textBtn: 'вежливо',
    },
    { 
      title: 'Реагируем на инциденты в течение 15 минут',
      description: 'В любое время суток, что бы ни случилось, мы отследим происшествие и постараемся решить проблему сами или проинформируем вас. Расширим мониторинг на основе инцидента, чтобы избежать его в будущем.',
      textBtn: 'оперативно',
    },
    { 
      title: 'Отслеживаем состояние инфраструктуры',
      description: 'Наши специалисты регулярно проверяют серверные показатели, чтобы убедиться, что все работает в штатном режиме. Это позволяет нам оперативно выявлять и устранять неполадки и реагировать на инциденты.',
      textBtn: 'внимательно',
    },
    { 
      title: 'Максимизируем отказоустойчивость',
      description: 'Проверим настройки сервера и оптимизируем их для повышения производительности и минимизации отказов. Обеспечим стабильность работы сервера.',
      textBtn: 'стабильно',
    },
    { 
      title: 'Обеспечиваем сохранность данных',
      description: 'Мы регулярно делаем резервные копии ваших данных на внешнем хранилище. Это гарантирует их сохранность в случае форс-мажоров',
      textBtn: 'надежно',
    },
    { 
      title: 'Отслеживаем метрики бизнеса',
      description: 'Собираем необходимые для аналитиков данные — просмотры, удержание, количество заказов, активность пользователей на сайте. Эта информация поможет вашему бизнесу оставаться эффективным и находить точки роста.',
      textBtn: 'эффективно',
    },
  ],
};

export const dataSecondSectionBigCards = {
  title: 'Основные направления <br> экосистемы SRE',
  items: [
    { 
      title: 'Мониторинг',
      description: 'Определение требований SLA, разработка и внедрение менеджмента алертов, внедрение системы мониторинга и SRE-инструментов.',
      textBtn: 'контроль',
    },
    { 
      title: 'Резервирование и резервное копирование',
      description: 'Определение требований, разработка и внедрение инструментов резервирования.',
      textBtn: 'надежность',
    },
    { 
      title: 'Поддержка инфраструктуры',
      description: 'Разработка и внедрение методологии IaC для автоматизации масштабирования и резервирования, методологии тестирования, подготовка технической документации по инфраструктуре.',
      textBtn: 'автоматизация',
    },
  ],
};

export const dataThirdSectionBigCards = {
  title: 'Мы предлагаем',
  items: [
    { 
      title: 'Круглосуточный мониторинг',
      description: 'Определение требований SLA, разработка и внедрение менеджмента алертов, внедрение системы мониторинга и SRE-инструментов.',
      textBtn: 'контроль',
    },
    { 
      title: 'Резервирование и резервное копирование',
      description: 'Определение требований, разработка и внедрение инструментов резервирования.',
      textBtn: 'надежность',
    },
    { 
      title: 'Поддержка инфраструктуры',
      description: 'Разработка и внедрение методологии IaC для автоматизации масштабирования и резервирования, методологии тестирования, подготовка технической документации по инфраструктуре.',
      textBtn: 'автоматизация',
    },
  ],
};





export const dataCircleCards = {
  title: 'Этапы внедрения',
  items: [
    { 
      title: 'Анализ <br> инфраструктуры',
      letter: 'а',
    },
    { 
      title: 'Разработка <br> SRE-экосистемы',
      letter: 'б',
    },
    { 
      title: 'Подготовка <br> инфраструктуры <br> под SRE',
      letter: 'в',
    },
    { 
      title: 'Внедрение <br> инструментов <br> SRE',
      letter: 'г',
    },
    { 
      title: 'Разработка <br> технической <br> документации',
      letter: 'д',
    },
    { 
      title: 'Ввод <br> в эксплуатацию',
      letter: 'е',
    },
  ],
};

export const dataTeamCards = {
  title: 'Команда',
  items: [
    { 
      title: 'Менеджер проекта',
      description: 'выстроит процессы и <br> проконтролирует достижение <br> результата',
    },
    { 
      title: 'Архитектор',
      description: 'составит ТЗ на основе бизнес-<br>требований и имеющейся <br> инфраструктуры',
    },
    { 
      title: 'SRE-инженеры',
      description: 'выстроят экосистему в соответствии <br> с ТЗ',
    },
    { 
      title: 'Технический писатель',
      description: 'подготовит подробную <br> документацию',
    },
  ],
};