import { makeStyles } from '@mui/styles';

const useStylesMainSection = makeStyles((theme) => ({
  main_section: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 57px)',
    padding: '24px 0 0',
    marginBottom: 56,
    [theme.breakpoints.up('bp_560')]: {
      minHeight: 'calc(100vh - 73px)',
      marginBottom: 48,
    },
    [theme.breakpoints.up('bp_900')]: {
      marginBottom: 64,
    },
    [theme.breakpoints.up('bp_1900')]: {
      marginBottom: 80,
    },
  },

  main_section__top_part: {
    paddingBottom: 72,
    [theme.breakpoints.up('bp_767')]: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 112,
    },
    [theme.breakpoints.up('bp_1200')]: {
      width: '50%',
      marginLeft: 'auto',
      paddingBottom: 95,
    },
  },

  main_section__top_part_text: {
    marginBottom: 16,
    ...theme.typography.h5,
    [theme.breakpoints.up('bp_767')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.up('bp_1200')]: {
      paddingLeft: 24,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h5_1900,
    },
  },

  main_section__top_part_wrap_icon_logo: {
    '& svg': {
      width: 56,
      height: 56,
      display: 'block',
      [theme.breakpoints.up('bp_768')]: {
        width: 72,
        height: 72,
      },
      [theme.breakpoints.up('bp_1900')]: {
        width: 96,
        height: 96,
      },
    },
  },

  main_section__bottom_part: {

  },

  main_section__bottom_part_wrap_strs: {
    marginBottom: 32,
    borderBottom: `1px solid ${theme.palette.black_80}`,
    [theme.breakpoints.up('bp_560')]: {
      marginBottom: 40,
    },
    [theme.breakpoints.up('bp_900')]: {
      marginBottom: 64,
    },
    [theme.breakpoints.up('bp_1900')]: {
      marginBottom: 80,
    },
  },

  main_section__top_part_wrap_btns: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  main_section__top_part_wrap_btn_normal: {
    opacity: 1,
    width: 222,
    [theme.breakpoints.up('bp_560')]: {
      width: 238,
    },
    [theme.breakpoints.up('bp_1900')]: {
      width: 318,
    },
    '&.hide_big_btn': {
      opacity: 0,
    },
  },

  main_section__top_part_wrap_btn_circle: {
    marginLeft: 'auto',
  },
  layout__btn: {
    width: '100%',
    marginBottom: 24,
    [theme.breakpoints.up('bp_560')]: {
      width: 238,
      marginBottom: 32,
    },
    [theme.breakpoints.up('bp_900')]: {
      width: 238,
      marginBottom: 0,
    },
    [theme.breakpoints.up('bp_1900')]: {
      width: 318,
      marginBottom: 0,
    },
    '&.long_big_btn': {
      width: '100%',
      marginBottom: 24,
      [theme.breakpoints.up('bp_560')]: {
        width: 238,
        marginBottom: 32,
      },
      [theme.breakpoints.up('bp_900')]: {
        width: 238,
        marginBottom: 0,
      },
      [theme.breakpoints.up('bp_1900')]: {
        width: 318,
        marginBottom: 0,
      },
    },
  },
  hiddenBr: {
    display: 'block',
    [theme.breakpoints.down(650)]: {
      display: 'none',
    },
  },
  development__title__hiddenBr: {
    display: 'block',
    [theme.breakpoints.down(1200)]: {
      display: 'none',
    },
    [theme.breakpoints.down(900)]: {
      display: 'block',
    },
  },

  
}));

export default useStylesMainSection;
