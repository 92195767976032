import { makeStyles } from '@mui/styles';

const useStylesTeamCard = makeStyles((theme) => ({
  team_card: {
    borderTop: `1px solid ${theme.palette.white}`,
    padding: '24px 0',
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
    },
    [theme.breakpoints.up('bp_1900')]: {
      padding: '32px 0',
    },
    '&:nth-child(odd) > div': {
      [theme.breakpoints.up('bp_560')]: {
        paddingRight: 24,
        borderRight: `1px solid ${theme.palette.black_80}`,
      },
      [theme.breakpoints.up('bp_1900')]: {
        paddingRight: 32,
      },
    },
    '&:nth-child(even) > div': {
      [theme.breakpoints.up('bp_560')]: {
        paddingLeft: 24,
      },
      [theme.breakpoints.up('bp_1900')]: {
        paddingLeft: 32,
      },
    },
    '&:nth-child(1)': {
      [theme.breakpoints.up('bp_560')]: {
        borderTop: 'none',
      },
    },
    '&:nth-child(2)': {
      [theme.breakpoints.up('bp_560')]: {
        borderTop: 'none',
      },
    },
  },

  team_card__content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 119,
    [theme.breakpoints.up('bp_560')]: {
      height: 144,
    },
    [theme.breakpoints.up('bp_1900')]: {
      height: 191,
    },
  },

  team_card__card_text: {
    ...theme.typography.h3,
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h3_900,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h3_1900,
    },
  },

  team_card__card_description: {
    ...theme.typography.P2_R_900,
    color: theme.palette.black_60,
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.P2_R_1900,
    },
  },

  
}));

export default useStylesTeamCard;
