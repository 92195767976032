import { makeStyles } from '@mui/styles';

const useStylesCircleCard = makeStyles((theme) => ({
  circle_card: {
    display: 'flex',
    justifyContent: 'center',
    
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
    },
    [theme.breakpoints.up('bp_768')]: {
      width: '33.3%',
    },
    [theme.breakpoints.up('bp_900')]: {
      width: '50%',
    },
    [theme.breakpoints.up('bp_1280')]: {
      width: '33.3%',
    },
  },

  circle_card__circle: {
    minWidth: 224,
    maxWidth: 224,
    height: 224,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.black_80}`,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('bp_1900')]: {
      minWidth: 298,
      maxWidth: 298,
      height: 298,
    },
  },

  circle_card__title: {
    ...theme.typography.h5,
    [theme.breakpoints.up('bp_1900')]: {
      fontSize: '24px',
      lineHeight: '30px',
    },
  },

  circle_card__wrap_btn: {
    width: 40,
    position: 'absolute',
    top: 9,
    right: 24,
    overflow: 'hidden',
    [theme.breakpoints.up('bp_1900')]: {
      width: 54,
    },
    '& button': {
      cursor: 'default',
    },
    '& button.mini:hover': {
      cursor: 'default',
      background: theme.palette.red,
    },
    '& button.mini:active': {
      cursor: 'default',
      opacity: 1,
    },
  },
  
}));

export default useStylesCircleCard;
