import { makeStyles } from '@mui/styles';

const useStylesDiscussProject = makeStyles((theme) => ({
  discuss_project: {
    minHeight: '100vh',
    
  },

  discuss_project__header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 0',
    borderBottom: `1px solid ${theme.palette.white}`,
    [theme.breakpoints.up('bp_560')]: {
      padding: '24px 0',
    },
  },

  discuss_project__header_word: {
    ...theme.typography.h5,
  },

  discuss_project__wrap_icon_close: {
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
    '& svg': {
      display: 'block',
      width: 24,
      height: 24,
    },
  },

  discuss_project__container: {
    
  },

  discuss_project__content: {
    padding: '24px 0',
    [theme.breakpoints.up('bp_1280')]: {
      display: 'flex',
    },
  },

  discuss_project__left_part: {
    [theme.breakpoints.up('bp_1280')]: {
      borderRight: `1px solid ${theme.palette.black_80}`,
      minWidth: 424,
      maxWidth: 424,
      paddingRight: 24,
    },
  },

  discuss_project__title: {
    ...theme.typography.h4,
    color: theme.palette.red,
    marginBottom: 24,
    [theme.breakpoints.up('bp_900')]: {
      marginBottom: 48,
    },
  },

  discuss_project__wrap_tabs: {
    display: 'flex',
    marginBottom: 24,
    [theme.breakpoints.up('bp_900')]: {
      marginBottom: 48,
    },
  },

  discuss_project__tab: {
    width: 'auto',
    height: 40,
    borderRadius: 16,
    background: theme.palette.black_90,
    color: theme.palette.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '200ms',
    ...theme.typography.h6,
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
    padding: '0 16px',
    fontVariantNumeric: 'oldstyle-nums',
    cursor: 'pointer',

    [theme.breakpoints.up('bp_1900')]: {
      height: 56,
      borderRadius: 21,
    },

    '&.active': {
      background: theme.palette.red,
    },
    
    '&:hover': {
      transition: '200ms',
      background: theme.palette.black_95,
      color: theme.palette.red,
    },
    '&:active': {
      transition: '200ms',
      opacity: '0.6',
    },

    '&:first-child': {
      marginRight: 16,
      [theme.breakpoints.up('bp_1900')]: {
        marginRight: 22,
      },
    },
  },

  discuss_project__wrap_content_tabs: {

  },

  discuss_project__content_cell: {
    marginBottom: 24,
  },

  discuss_project__content_label: {
    ...theme.typography.P4_R_900,
    color: theme.palette.black_60,
  },

  discuss_project__content_text: {
    ...theme.typography.P2_R_900,
    color: theme.palette.white,
  },

  discuss_project__wrap_right_part: {
    width: '100%',
  },

  discuss_project__right_part: {
    width: '100%',
    paddingTop: 24,
    borderTop: `1px solid ${theme.palette.black_80}`,
    [theme.breakpoints.up('bp_1280')]: {
      borderTop: 'none',
      paddingLeft: 24,
      paddingTop: 0,
      minHeight: 'calc(100vh - 121px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },

  discuss_project__right_part_content: {
    [theme.breakpoints.up('bp_1280')]: {
      
    },
  },

  discuss_project__wrap_elem: {
    [theme.breakpoints.up('bp_900')]: {
      width: '50%',
      paddingRight: 24,
    },
  },

  discuss_project__wrap_elem_capcha: {
    [theme.breakpoints.up('bp_900')]: {
      width: '50%',
      paddingRight: 24,
    },
  },

  discuss_project__wrap_double_elem: {
    [theme.breakpoints.up('bp_900')]: {
      display: 'flex',
    },
    '& > div': {
      [theme.breakpoints.up('bp_900')]: {
        width: '50%',
      },
    },
    '& > div:first-child': {
      [theme.breakpoints.up('bp_900')]: {
        marginRight: 24,
      },
    },
    '& > div:last-child': {
      [theme.breakpoints.up('bp_900')]: {
        marginLeft: 24,
      },
    },
  },

  discuss_project__wrap_checkbox: {
    marginTop: 28,
    marginBottom: 24,
    [theme.breakpoints.up('bp_560')]: {
      marginTop: 42,
      marginBottom: 42,
    },
  },

  discuss_project__wrap_text_capcha: {
    ...theme.typography.P4_R_900,
    color: theme.palette.black_60,
    marginTop: 24,
    [theme.breakpoints.up('bp_560')]: {
      // marginTop: 68,
    },
    [theme.breakpoints.up('bp_1280')]: {
    },
  },

  discuss_project__wrap_btn: {
    width: '100%',
    [theme.breakpoints.up('bp_560')]: {
      width: 312,
    },
    [theme.breakpoints.up('bp_1280')]: {
      width: 400,
    },
  },

  discuss_project__success: {

  },

  discuss_project__success_title: {
    ...theme.typography.h2,
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h2_560,
    },
    [theme.breakpoints.up('bp_1280')]: {
      ...theme.typography.h2_900,
    },
  },

  discuss_project__success_description: {
    ...theme.typography.h2,
    color: theme.palette.black_70,
    marginBottom: 24,
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h2_560,
    },
    [theme.breakpoints.up('bp_1280')]: {
      ...theme.typography.h2_900,
    },
  },

  discuss_project__wrap_icon_logo: {
    '& svg': {
      width: 56,
      height: 56,
      display: 'block',
      [theme.breakpoints.up('bp_560')]: {
        width: 72,
        height: 72,
      },
    },
  },

  discuss_project__container_wrap_capcha: {
    
    [theme.breakpoints.up('bp_900')]: {
      display: 'flex',
      width: '50%',
      paddingRight: 24,
    },
  },
  discuss_project__container_wrap_capcha__error: {
    marginBottom: 20,
    color: '#EE443A',
  },

  discuss_project__wrap_capcha: {
    marginTop: 16,
    marginRight: 30,
  },

}));

export default useStylesDiscussProject;
