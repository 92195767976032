import { makeStyles } from '@mui/styles';

const useStylesPreloader = makeStyles((theme) => ({
  preloader: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    minHeight: '100%',
    height: '100%',

    transition: 'all 500ms ease-in-out',
    background: theme.palette.black_100,
    zIndex: 10000,
    overflow: 'auto',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  preloader__wrap_elem: {
    width: 96,
    height: 96,
    position: 'relative',
  },

  preloader__icon_logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& svg': {
      width: 56,
      height: 56,
      display: 'block',
      [theme.breakpoints.up('bp_560')]: {
        width: 72,
        height: 72,
      },
    },
  },

  
  '@global': {

    // круговая анимация
    '.wrap-svg-circle': {
      transform: 'rotate(-90deg)',
    },
    '.svg-circle': {
      strokeDasharray: 300,
      animation:' dash2 2s linear forwards',
    },

    '@keyframes dash2': {
      'from': {
        strokeDashoffset: 300,
      },
      'to': {

      },
    },
    // круговая анимация

    // исчезновение прелоадера спустя 2 сек
    '.wrap_elem': {
      animation:' hideElem 0.3s linear forwards 2s',
    },

    '@keyframes hideElem': {
      'from': {
        transform: 'scale(1)',
      },
      'to': {
        transform: 'scale(0)',
      },
    },
    // исчезновение прелоадера  спустя 2 сек


    // исчезновение всего окна спустя 2.4 сек
    '.preloader': {
      animation:' hidePreloader 0.3s linear forwards 2.4s',
    },

    '@keyframes hidePreloader': {
      'from': {
      },
      'to': {
        opacity: 0,
        top: '-100vh',
      },
    },
    // исчезновение всего окна  спустя 2.4 сек


  },
}));

export default useStylesPreloader;
