import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import {
  disablePageScroll, enablePageScroll, clearQueueScrollLocks,
} from 'scroll-lock';

import { modalSelector } from '../../store/selectors/commonSelectors';
import { modalAction } from '../../store/actions/commonActions';

import Section from '../Section/Section';

import ModalMenu from './contentModals/ModalMenu/ModalMenu';
import useStylesModalUI from './ModalUIStyles';
import DiscussProject from './contentModals/DiscussProject/DiscussProject';

Modal.setAppElement('#root');

const ModalUI = function ({
  modal = {},
  modalAction = () => { },
}) {
  const classes = useStylesModalUI();
  const [refModalOverlay, setRefModalOverlay] = React.useState(null);
  const { bool, typeModal } = modal;
  const time = 500;

  // отключает скролл у body тем самым предотвращает дерганье экрана
  useEffect(() => {
    // refModalOverlay помещаем как аргумент для того, что бы в мобилке работал скролл
    if (bool) {
      disablePageScroll(refModalOverlay);
      // const htmlTag = document.getElementsByTagName('html')[0];
      // htmlTag.style.overflow = 'hidden';
      // console.log('dd lenis lenis-smooth', htmlTag);
    }
    if (!bool) {

      const timer = setTimeout(() => {
        clearQueueScrollLocks(refModalOverlay);
        enablePageScroll(refModalOverlay);
      }, time);
      return () => clearTimeout(timer);
    }
  }, [bool, refModalOverlay]);

  
 
  // закрывает попап
  const handlerCloceModal = () => {
    // typeModal нужно обязательно передавать иначе обратной анимации не будет, тупо будет исчезать 
    // (потому что, такое выражение " typeModal === '???' " удаляет элемент из дом дерева)
    modalAction(typeModal, false);
    // очищаем состояние
    setTimeout(() => modalAction('', false), time);
  };

  // содержит центральные конте`нты модального окна
  const typeModalSwitch = () => (
    <>
      {typeModal === 'menu' && <ModalMenu handlerCloceModal={handlerCloceModal} />}
      {typeModal === 'discuss_project' && <DiscussProject handlerCloceModal={handlerCloceModal} />}
    </>
  );

  return (
    <div className={classes.modal_ui}>
      <Modal
        closeTimeoutMS={time}
        isOpen={bool}
        overlayRef={(node) => (setRefModalOverlay(node))}
        onRequestClose={handlerCloceModal}
        overlayClassName={`ReactModal__Overlay ${classes.modal_ui__overlay} ${modal?.typeModal === 'menu' ? 'menu' : ''}`}
        className={`ReactModal__Content ${classes.modal_ui__wrap_content}`}
      >
        <div className={classes.modal_ui__content}>
          <Section>
            {typeModalSwitch()}
          </Section>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  modal: modalSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  modalAction: (bool, typeModal) => dispatch(modalAction(bool, typeModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalUI);
