import React, { useEffect } from 'react';
import 'animate.css';
import WOW from 'wowjs';

import useStylesAnimatedElem from './AnimatedElemStyles';

const AnimatedElem = ({
  children,
  animate = 'fade_in_up_small',
  delay = 0,
  className = '',
  notScroll = false,
}) => {
  useStylesAnimatedElem();
  
  // инициализация, этот пакет позволяет запускать анимацию когда анимированный элемент оказывается в поле зрения
  // для этого нужно добавить класс "wow"
  useEffect(() => {
    new WOW.WOW({ live: false }).init();
  }, []);

  return (
    <div className={`${animate} animation_delay_small_${delay}ms  ${notScroll ? '' : 'wow'} ${className}`}>
      {children}
    </div>
  );
};

export default AnimatedElem;
