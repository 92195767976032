import { makeStyles } from '@mui/styles';

const useStylesTicker = makeStyles((theme) => ({
  ticker: {
    position: 'relative',
  },

  // для анимации
  ticker__hovered: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: theme.palette.black_100,
    zIndex: 3,
    // transform: 'translateZ(0px)',
    // transform: 'rotateX(90deg)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'opacity top300ms',
    opacity: 0,
  },

  ticker__wrap_marquee: {
    borderTop: `1px solid ${theme.palette.black_80}`,
    width: '100%',
    whiteSpace: 'nowrap',
    // paddingTop: 12,
    paddingBottom: 8,
    [theme.breakpoints.up('bp_560')]: {
      // paddingTop: 16,
      paddingBottom: 0,
    },
    [theme.breakpoints.up('bp_900')]: {
      // paddingTop: 24,
    },
    '& div': {
      overflowY: 'hidden',
    },
    '& div div': {
      ...theme.typography.h1,
      [theme.breakpoints.up('bp_560')]: {
        ...theme.typography.h1_560,
      },
      [theme.breakpoints.up('bp_900')]: {
        ...theme.typography.h1_900,
      },
      [theme.breakpoints.up('bp_1900')]: {
        ...theme.typography.h1_1900,
      },
    },
  },

  ticker__elem_left_hovered: {
    ...theme.typography.h3_mini,
    color: theme.palette.red,
    [theme.breakpoints.up('bp_900')]: {
      width: '50%',
      ...theme.typography.h3_mini_900,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h3_mini_1900,
    },
  },

  ticker__elem_right_hovered: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('bp_900')]: {
      width: '50%',
    },
  },

  ticker__elem_right_hovered_description: {
    ...theme.typography.P2_R_900,
    display: 'none',
    [theme.breakpoints.up('bp_900')]: {
      display: 'block',
      paddingLeft: 24,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.P2_R_1900,
    },
  },

  ticker__wrap_icon_down_arr: {
    '& button': {
      width: '44px !important',
      minWidth: '44px !important',
      height: '44px !important',
      [theme.breakpoints.up('bp_560')]: {
        width: '72px !important',
        minWidth: '72px !important',
        height: '72px !important',
      },
      [theme.breakpoints.up('bp_1900')]: {
        width: '96px !important',
        minWidth: '96px !important',
        height: '96px !important',
      },
    },
  },

  ticker__wrap_new: {
    position: 'relative',
    padding: '48px 0',
    borderTop: `1px solid ${theme.palette.black_80}`,
    [theme.breakpoints.down(1300)]: {
      padding: '32px 0',
    },
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover h2': {
      color: '#EE443A',
    },
    '& h2': {
      transition: 'color 0.1s',
      fontSize: '104px',
      lineHeight: '120px',
      fontWeight: 400,
      marginTop: 0,
      marginBottom: '32px',
      [theme.breakpoints.down(1900)]: {
        fontSize: '72px',
        lineHeight: '72px',
      },
      [theme.breakpoints.down(1200)]: {
        fontSize: '44px',
        lineHeight: '48px',
        marginBottom: '16px',
      },
      [theme.breakpoints.down(561)]: {
        fontSize: '26px',
        lineHeight: '28px',
        fontFamily: 'HarmoniaBureauSansProCyr',
        fontWeight: 600,
      },
    },
    '& p': {
      margin: '0',
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 400,
      [theme.breakpoints.down(1900)]: {
        fontSize: '22px',
        lineHeight: '28px',
      },
      [theme.breakpoints.down(1200)]: {
        fontSize: '18px',
        lineHeight: '24px',
      },
    },
    '&:hover .ticker__wrap_icon_down_arr__new__static': {
      opacity: 1,
    },
  },
  ticker__wrap_icon_down_arr__new: {
    position: 'absolute',
    top: '76px',
    right: 0,
    opacity: 0,
    transition: 'opacity 0.15s',
    [theme.breakpoints.down(1900)]: {
      top: '48px',
    },
    [theme.breakpoints.down(1300)]: {
      display: 'none',
    },
  },

  

  '@global': {
    // когда наводим курсор
    '.for_ticker': {
      animation: ' forTicker 0.3s linear forwards',
    },

    '@-webkit-keyframes forTicker': {
      'from': {
        opacity: 0,
      },
      'to': {
        opacity: 1,
      },
    },

    '@keyframes forTicker': {
      'from': {
        opacity: 0,
      },
      'to': {
        opacity: 1,
      },
    },
    // когда наводим курсор

    // когда убираем курсор
    '.for_ticker2': {
      animation: ' forTicker2 0.3s linear forwards',
    },

    '@-webkit-keyframes forTicker2': {
      'from': {
        opacity: 1,
      },
      'to': {
        opacity: 0,
      },
    },

    '@keyframes forTicker2': {
      'from': {
        opacity: 1,
      },
      'to': {
        opacity: 0,
      },
    },
    // когда убираем курсор



    // появление слева
    '.sc_left': {
      animation: ' scLeft 0.3s linear forwards',
    },

    '@-webkit-keyframes scLeft': {
      'from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(-130px, 0, 0) scale(0.8)',
        transform: 'translate3d(-130px, 0, 0) scale(0.8)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0) scale(1)',
        transform: 'translate3d(0, 0, 0) scale(1)',
      },
    },

    '@keyframes scLeft': {
      'from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(-130px, 0, 0) scale(0.8)',
        transform: 'translate3d(-130px, 0, 0) scale(0.8)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0) scale(1)',
        transform: 'translate3d(0, 0, 0) scale(1)',
      },
    },
    // появление слева

    // появление справа
    '.sc_right': {
      animation: ' scRight 0.3s linear forwards',
    },

    '@-webkit-keyframes scRight': {
      'from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(100px, 0, 0) scale(0.8)',
        transform: 'translate3d(100px, 0, 0) scale(0.8)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0) scale(1)',
        transform: 'translate3d(0, 0, 0) scale(1)',
      },
    },

    '@keyframes scRight': {
      'from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(100px, 0, 0) scale(0.8)',
        transform: 'translate3d(100px, 0, 0) scale(0.8)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0) scale(1)',
        transform: 'translate3d(0, 0, 0) scale(1)',
      },
    },
    // появление справа

    // появление справа чуть-чуть
    '.sc_right_small': {
      animation: ' scRightSmall 0.3s linear forwards',
    },

    '@-webkit-keyframes scRightSmall': {
      'from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(10px, 0, 0) scale(0.8)',
        transform: 'translate3d(10px, 0, 0) scale(0.8)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0) scale(1)',
        transform: 'translate3d(0, 0, 0) scale(1)',
      },
    },

    '@keyframes scRightSmall': {
      'from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(10px, 0, 0) scale(0.8)',
        transform: 'translate3d(10px, 0, 0) scale(0.8)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0) scale(1)',
        transform: 'translate3d(0, 0, 0) scale(1)',
      },
    },
    // появление справа чуть-чуть
  },
}));

export default useStylesTicker;
