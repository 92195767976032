import React from 'react';

import HorizontalCard from '../HorizontalCard/HorizontalCard';

import useStylesHorizontalCardBlock from './HorizontalCardBlockStyles';

const HorizontalCardBlock = ({
  data = [],
}) => {
  const classes = useStylesHorizontalCardBlock();

  return (
    <div className={classes.horizontal_card_block}>
      <div className={classes.horizontal_card_block__parts}>
        <div
          className={classes.horizontal_card_block__left_part}
        >
          {data?.texts?.title}
        </div>
        <div className={classes.horizontal_card_block__right_part}>
          <div className={classes.horizontal_card_block__right_part_text}>
            {data?.texts?.description}
          </div>
        </div>
      </div>

      <div className={`${classes.horizontal_card_block__container}`}>
        <div className={`${classes.horizontal_card_block__all_cards}`}>
          {data?.cards?.map((item, index) =>
            <div className={`${classes.horizontal_card_block__wrap_card} panel`} key={index}>
              <HorizontalCard data={item} num={index + 1}/>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HorizontalCardBlock;
