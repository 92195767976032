import React from 'react';

import Nums from '../Nums/Nums';

import useStylesHeaders from './HeadersStyles';

const Headers = ({
  section = 'first',
  nums = [],
  words = [],
}) => {
  const classes = useStylesHeaders();

  return (
    <div className={classes.headers}>
      <div className={classes.headers__wrap_nums} >
        <Nums nums={nums} />
      </div>

      <h2 className={`${classes.headers__wrap_title} ${section}`}>
        {words?.map((i, index) => 
          <React.Fragment key={index}>
            <div className={classes.headers__hidden_block}>
              <div className={classes.headers__text}>{i}</div>
            </div>
          </React.Fragment>
        )}
      </h2>
    </div>
  );
};

export default Headers;
