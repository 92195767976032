import React from 'react';

import CircleCard from '../CircleCard/CircleCard';

import useStylesCircleCardBlock from './CircleCardBlockStyles';

const CircleCardBlock = ({
  data = {},
}) => {
  const classes = useStylesCircleCardBlock();

  return (
    <div className={classes.circle_card_block}>
      <div className={classes.circle_card_block__container}>
        <div
          className={classes.circle_card_block__left_part}
          dangerouslySetInnerHTML={{ __html: data?.title }}
        />

        <div className={classes.circle_card_block__right_part}>
          {data?.items?.map((item, index) =>
            <React.Fragment key={index}>
              <CircleCard data={item}/>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default CircleCardBlock;
