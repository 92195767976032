import { makeStyles } from '@mui/styles';

const useStylesButtonUI = makeStyles((theme) => ({
  button_ui: {
    '&.big': {
      width: '100%',
      height: 56,
      borderRadius: 40,
      background: theme.palette.red,
      color: theme.palette.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: '200ms',
      ...theme.typography.h4,
      '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
      [theme.breakpoints.up('bp_560')]: {
        height: 72,
      },
      [theme.breakpoints.up('bp_1900')]: {
        height: 96,
        borderRadius: 53,
        ...theme.typography.h4_1900,
      },
      '&:hover': {
        transition: '200ms',
        background: theme.palette.red_medium,
      },
      '&:active': {
        transition: '200ms',
        opacity: '0.6',
      },
    },

    '&.big-narrow': {
      width: '100%',
      height: 56,
      borderRadius: 40,
      background: theme.palette.red,
      color: theme.palette.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: '200ms',
      ...theme.typography.h4,
      [theme.breakpoints.up('bp_1900')]: {
        height: 72,
        ...theme.typography.h4_1900,
      },
      '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
      '&:hover': {
        transition: '200ms',
        background: theme.palette.red_medium,
      },
      '&:active': {
        transition: '200ms',
        opacity: '0.6',
      },
      '&:disabled': {
        background: theme.palette.black_90,
        transition: '200ms',
        opacity: '0.6',
      },
    },

    '&.mini': {
      width: '100%',
      height: 40,
      borderRadius: 16,
      background: theme.palette.red,
      color: theme.palette.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: '200ms',
      // ...theme.typography.h6,
      '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
      fontVariantNumeric: 'oldstyle-nums',
      [theme.breakpoints.up('bp_1900')]: {
        height: 54,
        borderRadius: 21,
        ...theme.typography.h6_1900,
      },
      '&:hover': {
        transition: '200ms',
        background: theme.palette.red_medium,
      },
      '&:active': {
        transition: '200ms',
        opacity: '0.6',
      },
    },

    '&.circle': {
      minWidth: 56,
      width: 56,
      height: 56,
      borderRadius: '50%',
      border: `1px solid ${theme.palette.white}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: '200ms',
      '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
      [theme.breakpoints.up('bp_560')]: {
        minWidth: 72,
        width: 72,
        height: 72,
      },
      [theme.breakpoints.up('bp_1900')]: {
        minWidth: 96,
        width: 96,
        height: 96,
      },
      '&:hover': {
        transition: '200ms',
        border: `1px solid ${theme.palette.black_80}`,
        background: theme.palette.black_80,
      },
      '&:active': {
        transition: '200ms',
        opacity: '0.6',
      },
      '& svg': {
        display: 'block',
        width: 24,
        height: 24,
        [theme.breakpoints.up('bp_1900')]: {
          width: 32,
          height: 32,
        },
      },
    },
  },


  
}));

export default useStylesButtonUI;
