import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Helmet} from 'react-helmet';

import IconUI from '../UI/IconUI/IconUI';
import Ticker from '../Ticker/Ticker';
import ButtonUI from '../UI/ButtonUI/ButtonUI';
import { modalAction } from '../../store/actions/commonActions';

import useStylesMainSection from './MainSectionStyles';

const MainSection = ({
  modalAction = () => {},
}) => {
  const classes = useStylesMainSection();
  const navigate = useNavigate();

  const [scrollBody, setScrollBody] = useState(document.documentElement.scrollTop); // получаем значение скролла у body

  useEffect(() => {
    // получаем доступ в тегу body и его проскроленному значению
    const handleScrollBody = () => setScrollBody(document.documentElement.scrollTop);
    window.addEventListener('scroll', handleScrollBody);
  
    return () => {
      window.removeEventListener('scroll', handleScrollBody);
    };
  }, [scrollBody]);

  const goToSection = (section) => {
    window.scrollTo({top: 0});
    navigate(section);
  };

  return (
    <>
      <Helmet>
        <title>Техническая поддержка сайтов и удаленное администрирование серверов 24/7 - БюроБюро</title>
        <meta name="description" content="БюроБюро занимается технической поддержкой сайтов и удаленным администрированием серверов с 2006 года. Мы повышаем отказоустойчивость сайта, настраиваем мониторинг. Нам доверяют IT-аутсорсинг ведущие компании!" />
        <meta name="keywords" content="Техническая поддержка сайтов, удаленное администрирование серверов, IT-аутсорсинг, техническая поддержка серверов, поддержка серверов" />
      </Helmet>
      <div className={classes.main_section}>
        <div className={classes.main_section__top_part}>
          <div className={classes.main_section__top_part_text}>
            Обеспечиваем комплексную непрерывную<br/>
            техническую поддержку сайтов<br/>
            и высоконагруженных сервисов
          </div>

          <div className={classes.main_section__top_part_wrap_icon_logo}>
            <IconUI name="icon_logo"/>
          </div>
        </div>

        <div className={classes.main_section__bottom_part}>
          <div className={classes.main_section__bottom_part_wrap_strs}>
            <Ticker
              text="круглосуточная техподдержка ~"
              textHovered="Круглосуточная техподдержка"
              descriptionHovered={`Бесперебойная работа вашего проекта и гарантированная <div class="${classes.hiddenBr}"></div> реакция на инциденты в течение 15 минут`}
              onClickArrow={() => goToSection('/support')}
            />
            <Ticker
              text="разработка и внедрение комплексной SRE ~"
              textHovered={`Разработка и внедрение <div class="${classes.development__title__hiddenBr}"></div> комплексной SRE`}
              descriptionHovered={`Обеспечим максимальную надежность, доступность <div class="${classes.hiddenBr}"></div> и легкость внедрения нового функционала`}
              onClickArrow={() => goToSection('/development')}
              direction="left"
            />
            <Ticker
              text="техподдержка Битрикс ~"
              textHovered="Техподдержка Битрикс"
              descriptionHovered={`Обеспечим производительность и отказоустойчивость проектов <div class="${classes.hiddenBr}"></div> на 1С-Битрикс и 1С-Битрикс 24`}
              onClickArrow={() => goToSection('/bitrix')}
            />
          </div>
          <div
            className={`${classes.layout__btn}`}
            onClick={() => modalAction('discuss_project', true)}
          >
            <ButtonUI
              text="Обсудить проект"
              typeBtn="big"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  // modal: modalSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  modalAction: (bool, typeModal) => dispatch(modalAction(bool, typeModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainSection);
