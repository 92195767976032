import React, { useLayoutEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Helmet} from 'react-helmet';

import gsap from 'gsap'; // для маневров с карточками
import { ScrollTrigger } from 'gsap/ScrollTrigger'; // для маневров с карточками

import { dataManyServicesCards, dataSecondSectionBigCards, dataCircleCards, dataTeamCards } from '../../helpers/data-helper';

import ServicesCard from '../ServicesCard/ServicesCard';
import BigCardBlock from '../BigCardBlock/BigCardBlock';
import CircleCardBlock from '../CircleCardBlock/CircleCardBlock';
import TeamCardBlock from '../TeamCardBlock/TeamCardBlock';
import Headers from '../Headers/Headers';
import useResize from '../../helpers/useResize';

import useStylesDevelopmentSection from './DevelopmentSectionStyles';


gsap.registerPlugin(ScrollTrigger); // для маневров с карточками

const DevelopmentSection = () => {
  const classes = useStylesDevelopmentSection();
  const minWidth1280 = useMediaQuery('(min-width:1280px)');
  const width = useResize(0);

  // прикрепляем текст к верху экрана в области секции при скролле (Site Reliability Engineering)
  const wrapSection = useRef();
  const target = useRef();
  useLayoutEffect(() => {
    if (minWidth1280) {
      const ctx = gsap.context(() => {
        const heightSection = wrapSection.current.getBoundingClientRect().height;
        // const endheightSection = endSection.current.getBoundingClientRect().height;

        ScrollTrigger.create({
          trigger: target.current,
          start: `top-=${100} top`,
          end: () => '+=' + (heightSection - (600)),
          pin: true,
          pinSpacing: false,
          // markers: true,
        });

      }, wrapSection);
      return () => ctx.revert();
    }
  }, [width, minWidth1280]);

  return (
    <>
      <Helmet>
        <title>Разработка и внедрение комплексной SRЕ - БюроБюро</title>
        <meta name="description" content="БюроБюро обеспечит максимальную надежность, доступность и легкость внедрения нового функционала. Мониторинг серверов, резервирование и резервное копирование серверов, а так же поддержку ИТ инфраструктуры." />
        <meta name="keywords" content="Разработка SRE, Site Reliability Engineering, мониторинг серверов, резервное копирование серверов, поддержка ит инфраструктуры" />
      </Helmet>
      <div className={classes.development_section}>
        <div className={classes.development_section__not_line}>

          <Headers
            section="second"
            nums={['02', '03']}
            words={['разработка', 'и внедрение', 'комплексной', 'SRE']}
          />

          <div className={classes.development_section__wrap_text}>
            обеспечим максимальную надежность, доступность и легкость внедрения нового функционала
          </div>
        </div>

        {/*  */}

        <div className={classes.development_section__line} ref={wrapSection}>
          <div className={classes.development_section__line_parts}>
            <div className={classes.development_section__line_left_part} ref={target}>
              <div className={classes.development_section__line_left_part_text}>
                Site Reliability Engineering
              </div>

              <div className={classes.development_section__line_left_part_description}>
                SRE (Site Reliability Engineering) – методология, разработанная Google для улучшения качества обслуживания,
                повышения надежности и расширения возможностей масштабирования Интернет-сервисов. Объединяет в себе элементы
                разработки программного обеспечения (Dev) и управления операционными задачами (Ops); такие практики и инструменты,
                как автоматизация, мониторинг, тестирование, инцидент-менеджмент.
              </div>
            </div>
            <div className={classes.development_section__line_right_part}>
              <div className={classes.development_section__line_right_part_text}>
                SRE позволяет своевременно обнаруживать и устранять проблемы, связанные с надежностью,
                сохранять высокую доступность услуг для пользователей, быстрее внедрять новые функции,
                минимизировать время восстановления после сбоев.
              </div>
            </div>
          </div>

          <div className={classes.development_section__all_cards}>
            {dataManyServicesCards?.map((item, index) =>
              <React.Fragment key={index}>
                <ServicesCard item={item} num={index + 1} />
              </React.Fragment>
            )}
          </div>
        </div>

        {/*  */}

        <div>
          <BigCardBlock data={dataSecondSectionBigCards} />
        </div>

        {/*  */}

        <div>
          <CircleCardBlock data={dataCircleCards} />
        </div>

        {/*  */}

        <div>
          <TeamCardBlock data={dataTeamCards} />
        </div>

      </div>
    </>
  );
};

export default DevelopmentSection;