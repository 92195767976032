import React from 'react';

import useStylesListCard from './ListCardStyles';

const ListCard = ({
  data = {},
}) => {
  const classes = useStylesListCard();

  return (
    <div className={classes.list_card}>
      <div className={classes.list_card_title}>
        {data?.title}
      </div>

      <div className={classes.list_card__items}>
        {data?.items?.join(', ')}
      </div>
    </div>
  );
};

export default ListCard;
