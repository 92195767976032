import React from 'react';

import BigCard from '../BigCard/BigCard';

import useStylesBigCardBlock from './BigCardBlockStyles';


const BigCardBlock = ({
  data = {},
}) => {
  const classes = useStylesBigCardBlock();

  return (
    <div className={classes.big_card_block}>
      <div className={classes.big_card_block__container}>
        <div
          className={classes.big_card_block__left_part}
          dangerouslySetInnerHTML={{ __html: data?.title }}
        />

        <div className={`${classes.big_card_block__right_part}`}>
          <div className={`${classes.big_card_block__wrap_cards} cards`}>
            {data?.items?.map((item, index) =>
              <div className={`${classes.big_card_block__wrap_card} card`} key={index}>
                <BigCard data={item} num={index}/>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigCardBlock;
