import { makeStyles } from '@mui/styles';

const useStylesSupportSection = makeStyles((theme) => ({
  support_section: {
  },

  support_section__line: {
    borderTop: `1px solid ${theme.palette.white}`,
    paddingTop: 24,
    paddingBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      paddingBottom: 128,
    },
    [theme.breakpoints.up('bp_1900')]: {
      paddingTop: 32,
      paddingBottom: 170,
    },
  },

  support_section__not_line: {
    paddingTop: 24,
    paddingBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      paddingBottom: 128,
    },
    [theme.breakpoints.up('bp_1900')]: {
      paddingTop: 32,
      paddingBottom: 170,
    },
  },

  support_section__wrap_text: {
    marginLeft: 'auto',
    ...theme.typography.h2,
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h2_560,
    },
    [theme.breakpoints.up('bp_900')]: {
      width: '50%',
      ...theme.typography.h2_900,
      paddingLeft: 24,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h2_1900,
    },
  },

  support_section__container: {
    [theme.breakpoints.up('bp_560')]: {
      display: 'flex',
    },
  },

  support_section__container_left_part: {
    ...theme.typography.h4,
    color: theme.palette.red,
    marginBottom: 48,
    paddingRight: 20,
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
      marginBottom: 0,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h4_1900,
    },
  },

  support_section__container_right_part: {
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
    },
    [theme.breakpoints.up('bp_768')]: {
      paddingLeft: 24,
    },
  },

  support_section__container_small_text: {
    ...theme.typography.P2_R_900,
    marginBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      marginBottom: 112,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.P2_R_1900,
      marginBottom: 150,
    },
  },

  support_section__big_text: {
    ...theme.typography.h2,
    marginBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h2_560,
      marginBottom: 64,
      marginLeft: 'auto',
    },
    [theme.breakpoints.up('bp_900')]: {
      ...theme.typography.h2_900,
      width: '50%',
      paddingLeft: 24,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h2_1900,
      marginBottom: 85,
    },
  },

  support_section__all_cards: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 24,
    [theme.breakpoints.up('bp_560')]: {
      marginBottom: 88,
    },
    [theme.breakpoints.up('bp_1280')]: {
      width: '50%',
      marginLeft: 'calc(50% + 24px)',
    },
  },

  support_section__only_line: {
    textAlign: 'center',
    ...theme.typography.h2,
    color: theme.palette.red,
    marginBottom: 16,
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h2_560,
    },
    [theme.breakpoints.up('bp_900')]: {
      ...theme.typography.h2_900,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h2_1900,
    },
  },

  support_section__second_big_text: {
    ...theme.typography.h2,
    textAlign: 'center',
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h2_560,
    },
    [theme.breakpoints.up('bp_900')]: {
      ...theme.typography.h2_900,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h2_1900,
    },
  },

  support_section__container_levels: {
    [theme.breakpoints.up('bp_560')]: {
      display: 'flex',
    },
  },

  support_section__container_levels_left_part: {
    ...theme.typography.h4,
    color: theme.palette.red,
    marginBottom: 48,
    paddingRight: 20,
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h4_1900,
    },
  },

  support_section__container_levels_right_part: {
    marginBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      marginBottom: 112,
      width: '50%',
    },
    [theme.breakpoints.up('bp_1900')]: {
      marginBottom: 150,
    },
  },

  support_section__container_levels_text: {
    ...theme.typography.P2_R_900,
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.P2_R_1900,
    },
    [theme.breakpoints.up('bp_768')]: {
      paddingLeft: 24,
    },
  },

  support_section__container_levels_all_cards: {
    [theme.breakpoints.up('bp_1280')]: {
      width: '50%',
      marginLeft: 'calc(50% + 24px)',
    },
  },

  support_section__container_levels_wrap_card: {
    marginBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      marginBottom: 64,
    },
    [theme.breakpoints.up('bp_1900')]: {
      marginBottom: 85,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },

  support_section__container_levels_card: {
    borderTop: `1px solid ${theme.palette.white}`,
  },

  support_section__container_levels_card_wrap_num_title: {
    display: 'flex',
    marginBottom: 16,
    paddingTop: 8,
    [theme.breakpoints.up('bp_560')]: {
      paddingTop: 0,
    },
  },

  support_section__container_levels_card_num: {
    ...theme.typography.acid,
    color: theme.palette.red,
    width: '50%',
    paddingBottom: 7,
    fontVariantNumeric: 'oldstyle-nums',
    overflow: 'hidden',
    [theme.breakpoints.up('bp_560')]: {
      width: '120px',
      ...theme.typography.acid_900,
    },
    [theme.breakpoints.up('bp_1900')]: {
      width: '160px',
      ...theme.typography.acid_1900,
    },
  },

  support_section__container_levels_card_title: {
    ...theme.typography.acid,
    width: '50%',
    overflow: 'hidden',
    [theme.breakpoints.up('bp_560')]: {
      width: 'inherit',
      ...theme.typography.acid_900,
    },
    [theme.breakpoints.up('bp_900')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.acid_1900,
    },
  },

  support_section__container_levels_double_text: {
    borderTop: `1px solid ${theme.palette.black_80}`,
    [theme.breakpoints.up('bp_560')]: {
      display: 'flex',
      paddingTop: 24,
    },
    [theme.breakpoints.up('bp_1900')]: {
      paddingTop: 32,
    },
  },

  support_section__container_levels_first_text: {
    paddingTop: 24,
    paddingBottom: 24,
    borderBottom: `1px solid ${theme.palette.black_80}`,
    ...theme.typography.P2_R_900,
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 24,
      borderRight: `1px solid ${theme.palette.black_80}`,
      borderBottom: 'none',
    },
    [theme.breakpoints.up('bp_1900')]: {
      paddingRight: 32,
      ...theme.typography.P2_R_1900,
    },
  },

  support_section__container_levels_second_text: {
    paddingTop: 24,
    ...theme.typography.P2_M_900,
    color: theme.palette.red_light,
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
      paddingTop: 0,
      paddingLeft: 24,
    },
    [theme.breakpoints.up('bp_1900')]: {
      paddingLeft: 32,
      ...theme.typography.P2_M_1900,
    },
  },

  support_section__second_end_big_text: {
    textAlign: 'center',
    maxWidth: 1072,
    margin: '0 auto',
    marginBottom: 48,
    ...theme.typography.h2,
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h2_560,
      marginBottom: 112,
    },
    [theme.breakpoints.up('bp_900')]: {
      ...theme.typography.h2_900,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h2_1900,
      marginBottom: 150,
    },
  },

  support_section__container_end_small_text: {
    ...theme.typography.P2_R_900,
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
      marginLeft: 'auto',
    },
    [theme.breakpoints.up('bp_768')]: {
      paddingLeft: 24,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.P2_R_1900,
    },
  },

  support_section__container_list: {
    ...theme.typography.h4,
    color: theme.palette.red,
    marginBottom: 64,
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h4_1900,
      marginBottom: 85,
    },
  },

  support_section__container_all_lists: {

  },

}));

export default useStylesSupportSection;
