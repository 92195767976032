import React from 'react';

import useStylesTextAreaUI from './TextAreaUIStyles';

const TextAreaUI = function ({
  className = '',
  placeholder = '',
  max = 255,
  validation = {},
  error = false,
}) {
  const classes = useStylesTextAreaUI();

  return (
    <textarea
      placeholder={placeholder}
      className={`${classes.text_area_ui} ${error ? 'error' : ''} ${className}`}
      maxLength={max}
      {...validation}
    />
  );
};

export default TextAreaUI;
