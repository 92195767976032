import { makeStyles } from '@mui/styles';

const useStylesHeader = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 0',
    borderBottom: `1px solid ${theme.palette.white}`,
    [theme.breakpoints.up('bp_560')]: {
      padding: '24px 0',
    },
  },

  header__left_part: {
    width: '50%',
  },

  header__right_part: {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  header__logo: {
    ...theme.typography.h5,
    '& span:last-child': {
      color: theme.palette.red,
    },
  },

  header__wrap_icon_close: {
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
    '& svg': {
      display: 'block',
      width: 24,
      height: 24,
    },
  },
  header__wrap_icon_menu: {
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
    '& svg': {
      display: 'block',
      width: 62,
      height: 24,
    },
  },

  header__mail: {
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
    display: 'block',
    width: 24,
    marginLeft: 'auto',
    marginRight: 25,
    [theme.breakpoints.up('bp_560')]: {
      width: 'auto',
      marginLeft: 'inherit',
      marginRight: 'inherit',
    },
  },

  header__mail_text: {
    ...theme.typography.h5,
    color: theme.palette.red,
    display: 'none',
    [theme.breakpoints.up('bp_560')]: {
      display: 'block',
    },
    [theme.breakpoints.up('bp_768')]: {
      paddingLeft: 24,
    },
  },

  header__wrap_icon_mail: {
    display: 'block',
    [theme.breakpoints.up('bp_560')]: {
      display: 'none',
    },
    '& svg': {
      display: 'block',
      width: 24,
      height: 24,
    },
  },

  
}));

export default useStylesHeader;
