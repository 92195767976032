import React from 'react';
import { connect } from 'react-redux';

import { modalAction } from '../../store/actions/commonActions';

import useStylesFooter from './FooterStyles';

const Footer = ({
  modalAction = () => {},
}) => {
  const classes = useStylesFooter();

  return (
    <div className={classes.footer}>
      <div className={classes.footer__left_part}>
        <div className={classes.footer__copy_right}>
          © бюробюро, 2006 — {new Date().getFullYear()}
        </div>
      </div>

      <div className={classes.footer__right_part}>
        <div className={classes.footer__big_cell}>
          <div className={classes.footer__cell}>
            <div className={classes.footer__cell_title}>Москва</div>
            <div className={classes.footer__cell_description}>
              Варшавское шоссе, д. 1, стр. 6, <br></br>
              БЦ W Plaza 2 <br></br>
              <a href="tel:+74955323880">+7 (495) 532 38 80</a>
            </div>
          </div>
          <div className={classes.footer__cell}>
            <div className={classes.footer__cell_title}>Калининград</div>
            <div className={classes.footer__cell_description}>
              ул. Верхнеозёрная, д. 33А <br></br>
              <a href="tel:88005501238">8 (800) 550 12 38</a>
            </div>
          </div>
        </div>

        <div className={classes.footer__small_cell}>
          <div className={classes.footer__wrap_last_words}>
            <a onClick={(evt) => { evt.preventDefault(); modalAction('discuss_project', true); }} href="/#" className={classes.footer__last_word}>
              Контакты
            </a>
            <a target="_blank" rel="noreferrer" href="https://buroburo.ru/policy/" className={classes.footer__last_word}>
              Политика обработки персональных данных
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // modal: modalSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  modalAction: (bool, typeModal) => dispatch(modalAction(bool, typeModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
