import React from 'react';

import useStylesTeamCard from './TeamCardStyles';

const TeamCard = ({
  data = {},
}) => {
  const classes = useStylesTeamCard();

  return (
    <div className={classes.team_card}>
      <div className={classes.team_card__content}>
        <div className={classes.team_card__card_text}>
          {data?.title}
        </div>

        <div
          className={classes.team_card__card_description}
          dangerouslySetInnerHTML={{ __html: data?.description }}
        />
      </div>
    </div>
  );
};

export default TeamCard;
