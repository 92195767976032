import React from 'react';

import IconUI from '../IconUI/IconUI';

import useStylesInputFileUI from './InputFileUIStyles';

const InputFileUI = ({
  className = '',
  placeholder = '',
  validation = {},
  valueInputFile,
  error = false,
  text = '',
}) => {
  const classes = useStylesInputFileUI();

  const arrayFile = [];
  // Преобразуем псевдомассив в массив
  for (let i = 0; i < valueInputFile?.length; i++) {
    arrayFile.push(valueInputFile?.[i]);
  };

  return (
    <div className={classes.input_file_ui}>
      <label className={`${classes.input_file__label} ${arrayFile?.length > 0 ? 'full' : ''} ${className} ${error ? 'error' : ''}`}>
        <input
          type="file"
          // multiple
          {...validation}
        />
        {arrayFile?.length > 0
          && (
            <div className="app__wrap_input_file">
              {arrayFile?.map((item, index) =>
                <div
                  key={index}
                  className="input_file__item"
                >{item?.name}</div>
              )}
            </div>
          )}

        {arrayFile?.length < 1 && <div className='placeholder'>{placeholder}</div>}

        <div className={classes.input_file_ui__wrap_icon}>
          <IconUI name="icon_attach"/>
        </div>
      </label>
    </div>
  );
};

export default InputFileUI;