import React from 'react';

import IconUI from '../IconUI/IconUI';

import useStylesButtonUI from './ButtonUIStyles';

const ButtonUI = ({
  typeBtn = 'big',
  type = 'button',
  disabled = false,
  text = '',
  onClick = () => {},
}) => {
  const classes = useStylesButtonUI();

  if (typeBtn === 'big') {
    return (
      <button
        className={`${classes.button_ui} big`}
        type={type}
        disabled={disabled}
        onClick={onClick}
      >
        {text}
      </button>
    );
  }
  if (typeBtn === 'big-narrow') {
    return (
      <button
        className={`${classes.button_ui} big-narrow`}
        type={type}
        disabled={disabled}
        onClick={onClick}
      >
        {text}
      </button>
    );
  }

  if (typeBtn === 'mini') {
    return (
      <button
        className={`${classes.button_ui} mini`}
        type={type}
        disabled={disabled}
        onClick={onClick}
      >
        {text}
      </button>
    );
  }

  if (typeBtn === 'circle') {
    return (
      <button
        className={`${classes.button_ui} circle`}
        type={type}
        disabled={disabled}
        onClick={onClick}
      >
        <IconUI name="icon_arrow_down"/>
      </button>
    );
  }

  if (typeBtn === 'circle-next') {
    return (
      <button
        className={`${classes.button_ui} circle`}
        type={type}
        disabled={disabled}
        onClick={onClick}
      >
        <IconUI name="icon_arrow_right"/>
      </button>
    );
  }

  return (
    <button>
      простая
    </button>
  );
};

export default ButtonUI;
