/* eslint-disable no-console */
import { call, put } from 'redux-saga/effects';

import { actionTypes } from '../types';

// открывает модальное окно
export function modalAction(typeModal, bool) {
  return {
    type: actionTypes.MODAL,
    typeModal,
    bool,
  };
}

// флаг отвечающий за результат отправки формы
export function isSendDiscussProjectAction(bool) {
  return {
    type: actionTypes.IS_SEND_DISCUSS_PROJECT,
    bool,
  };
}


// инициализирует отправки формы ОБСУДИТЬ ПРОЕКТ
export function sendDiscussProjectFormAction(data) {
  return {
    type: actionTypes.SEND_DISCUSS_PROJECT,
    data,
  };
}

// отправляет данные с формы ОБСУДИТЬ ПРОЕКТ
export function* sendDiscussProjectFormSaga(action) {

  const dataForm = new FormData();
  
  dataForm.append('inputEmail', action?.data?.inputEmail);
  if (action?.data?.inputFile) dataForm.append('inputFile', action?.data?.inputFile);
  dataForm.append('inputName', action?.data?.inputName);
  dataForm.append('phone', action?.data?.inputPhone);
  dataForm.append('textAreaField', action?.data?.textAreaField);
  dataForm.append('captcha', action?.data?.captcha);

  try {
    const res = yield call(() => fetch('https://api.support.buroburo.ru/api/v1/feedback',
      {
        method: 'POST',
        body: dataForm,
      }
    ));
    const resBody = yield res.json();

    if (res?.status === 200) {
      yield put(isSendDiscussProjectAction(true));
    }
    else if(res?.status > 400 && res?.status < 500) {
      yield put({type: actionTypes.SEND_DISCUSS_PROJECT_ERROR, data: {errorMessage: resBody}});
    }
  } catch (err) {
    console.log(err);
  }
}

// инициализирует запрос получения reCaptcha v3
export function getRecaptchaAction() {
  return {
    type: actionTypes.GET_RECAPTCHA,
  };
}
// запрос публичного ключа reCaptcha v3
export function* getRecaptchaSaga(action) {
  try {
    const res = yield call(() => fetch('https://api.support.buroburo.ru/api/v1/settings',
      {
        method: 'GET',
      }
    ));
    const resBody = yield res.json();

    if (res?.status === 200) {
      yield put({type: actionTypes.GET_RECAPTCHA_SUCCESS, data: {recaptchaSiteKey: resBody.recaptchaSiteKey}});
    }
  } catch (err) {
    console.log(err);
  }
}