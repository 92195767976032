import React from 'react';
import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';

import IconUI from '../../../UI/IconUI/IconUI';

import { modalAction } from '../../../../store/actions/commonActions';

import useStylesModalMenu from './ModalMenuStyles';


const ModalMenu = function ({
  handlerCloceModal = () => { },
  modalAction = () => {},
}) {
  const classes = useStylesModalMenu();
  const navigate = useNavigate();

  // закрывает меню и скроллит к нужной секции
  const onClickToCard = (section) => {
    window.scrollTo({top: 0});
    handlerCloceModal();

    navigate(section);
    // let target = 'first';
    //
    // if (section === 'first') target = 'first';
    // else if (section === 'second') target = 'second';
    // else if (section === 'third') target = 'third';
    //
    // setTimeout(() => {
    //   scroller.scrollTo(`scroll-to-${target}-section`, {
    //     duration: 2000,
    //     delay: 0,
    //     offset: -73,
    //     smooth: 'easeInOutQuart',
    //   });
    // }, 300);
  };

  const openDiscussProject = () => {
    handlerCloceModal();
    setTimeout(() => {
      modalAction('discuss_project', true);
    }, 600);
  };

  return (
    <div className={classes.modal_menu}>
      <div className={classes.modal_menu__all_cards}>
        <div
          className={classes.modal_menu__card}
          onClick={() => onClickToCard('/support')}
        >
          <div className={`${classes.modal_menu__wrap_num} wrap_num`}>
            01
            <div className={`${classes.header__wrap_arrow_right} arrow`}>
              <IconUI name="icon_arrow_right" />
            </div>
          </div>

          <div className={classes.modal_menu__wrap_double_text}>
            <div className={`${classes.modal_menu__title_card} title_card`}>
              Круглосуточная <br></br>техподдержка
            </div>

            <div className={`${classes.modal_menu__description_card} description_card`}>
              бесперебойная работа вашего проекта и гарантированная реакция на инциденты в течение 15 минут
            </div>
          </div>
        </div>

        <div
          className={classes.modal_menu__card}
          onClick={() => onClickToCard('/development')}
        >
          <div className={`${classes.modal_menu__wrap_num} wrap_num`}>
            02
            <div className={`${classes.header__wrap_arrow_right} arrow`}>
              <IconUI name="icon_arrow_right" />
            </div>
          </div>

          <div className={classes.modal_menu__wrap_double_text}>
            <div className={`${classes.modal_menu__title_card} title_card`}>
              Разработка и внедрение комплексной SRE
            </div>

            <div className={`${classes.modal_menu__description_card} description_card`}>
              обеспечим максимальную надежность, доступность и легкость внедрения нового функционала
            </div>
          </div>
        </div>

        <div
          className={classes.modal_menu__card}
          onClick={() => onClickToCard('/bitrix')}
        >
          <div className={`${classes.modal_menu__wrap_num} wrap_num`}>
            03
            <div className={`${classes.header__wrap_arrow_right} arrow`}>
              <IconUI name="icon_arrow_right" />
            </div>
          </div>

          <div className={classes.modal_menu__wrap_double_text}>
            <div className={`${classes.modal_menu__title_card} title_card`}>
              Техподдержка <br></br>Битрикс
            </div>

            <div className={`${classes.modal_menu__description_card} description_card`}>
              обеспечим производительность и отказоустойчивость проектов на 1С-Битрикс и 1С-Битрикс 24
            </div>
          </div>
        </div>

      </div>

      <div className={classes.modal_menu__footer}>
        <div className={classes.modal_menu__footer_left_part}>
          <div className={classes.modal_menu__footer_left_part_cell}>
            <div className={classes.modal_menu__footer_left_part_cell_title}>Москва</div>

            <div className={classes.modal_menu__footer_left_part_cell_description}>
              Варшавское шоссе, д. 1, стр. 6, БЦ W Plaza 2 <br></br>
              <a href="tel:+74955323880">+7 (495) 532 38 80</a>
            </div>
          </div>

          <div className={classes.modal_menu__footer_left_part_cell}>
            <div className={classes.modal_menu__footer_left_part_cell_title}>Калининград</div>

            <div className={classes.modal_menu__footer_left_part_cell_description}>
              ул. Верхнеозёрная, д. 33А <br></br>
              <a href="tel:88005501238">8 (800) 550 12 38</a>
            </div>
          </div>
        </div>

        <div className={classes.modal_menu__footer_right_part}>
          <div className={classes.modal_menu__footer_wrap_last_words}>
            <a onClick={(evt) => { evt.preventDefault(); openDiscussProject(); }} href="/#"  className={classes.modal_menu__footer_last_word}>
              Контакты
            </a>

            <a href="https://buroburo.ru/policy/" className={classes.modal_menu__footer_last_word}>
              Политика обработки персональных данных
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // modal: modalSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  modalAction: (bool, typeModal) => dispatch(modalAction(bool, typeModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalMenu);
