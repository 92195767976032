import { makeStyles } from '@mui/styles';

const useStylesDevelopmentSection = makeStyles((theme) => ({
  development_section: {
  },

  development_section__line: {
    borderTop: `1px solid ${theme.palette.white}`,
    paddingTop: 24,
    paddingBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      paddingBottom: 128,
    },
    [theme.breakpoints.up('bp_1900')]: {
      paddingTop: 32,
      paddingBottom: 170,
    },
  },

  development_section__not_line: {
    paddingTop: 24,
    paddingBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      paddingBottom: 128,
    },
    [theme.breakpoints.up('bp_1900')]: {
      paddingTop: 32,
      paddingBottom: 170,
    },
  },

  development_section__wrap_nums: {
    width: '50%',
    marginLeft: 'auto',
  },

  development_section__wrap_title: {
    marginBottom: 48,
    [theme.breakpoints.up('bp_1900')]: {
      marginBottom: 64,
    },
    '& div': {
      ...theme.typography.h1,
      [theme.breakpoints.up('bp_768')]: {
        ...theme.typography.h1_560,
      },
      [theme.breakpoints.up('bp_1280')]: {
        ...theme.typography.h1_900,
      },
      [theme.breakpoints.up('bp_1900')]: {
        ...theme.typography.h1_1900,
      },
    },
    '& div:nth-child(1)': {
      textAlign: 'right',
    },
    '& div:nth-child(2)': {
      textAlign: 'left',
    },
    '& div:nth-child(3)': {
      textAlign: 'right',
    },
    '& div:nth-child(4)': {
      textAlign: 'right',
    },
  },

  development_section__wrap_text: {
    marginLeft: 'auto',
    ...theme.typography.h2,
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h2_560,
    },
    [theme.breakpoints.up('bp_900')]: {
      width: '50%',
      ...theme.typography.h2_900,
      paddingLeft: 24,
    },
  },

  development_section__line_parts: {
    [theme.breakpoints.up('bp_560')]: {
      display: 'flex',
    },
  },

  development_section__line_left_part: {
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
      paddingRight: 20,
    },
  },

  development_section__line_left_part_text: {
    ...theme.typography.h4,
    color: theme.palette.red,
    marginBottom: 24,
    paddingRight: 20,
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h4_1900,
      marginBottom: 32,
    },
  },

  development_section__line_left_part_description: {
    marginBottom: 48,
    ...theme.typography.P4_R_900,
    color: theme.palette.black_60,
    [theme.breakpoints.up('bp_560')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.up('bp_900')]: {
      maxWidth: 500,
      fontSize: '16px',
      lineHeight: '20px',
    },
  },

  development_section__line_right_part: {
    marginBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      marginBottom: 112,
      width: '50%',
    },
    [theme.breakpoints.up('bp_768')]: {
      paddingLeft: 24,
    },
    [theme.breakpoints.up('bp_1900')]: {
      marginBottom: 150,
    },
  },

  development_section__line_right_part_text: {
    ...theme.typography.P2_R_900,
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.P2_R_1900,
    },
  },

  development_section__all_cards: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 24,
    [theme.breakpoints.up('bp_560')]: {
      marginBottom: 88,
    },
    [theme.breakpoints.up('bp_1280')]: {
      width: '50%',
      marginLeft: 'calc(50% + 24px)',
    },
  },

  development_section__all_circle_cards: {

  },
  

}));

export default useStylesDevelopmentSection;
