import { makeStyles } from '@mui/styles';

const useStylesHorizontalCardBlock = makeStyles((theme) => ({
  horizontal_card_block: {
    borderTop: `1px solid ${theme.palette.white}`,
    paddingTop: 24,
    paddingBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      paddingBottom: 128,
    },
    [theme.breakpoints.up('bp_1900')]: {
      paddingTop: 32,
      paddingBottom: 170,
    },
  },

  horizontal_card_block__parts: {
    [theme.breakpoints.up('bp_560')]: {
      display: 'flex',
    },
  },

  horizontal_card_block__left_part: {
    ...theme.typography.h4,
    color: theme.palette.red,
    marginBottom: 48,
    paddingRight: 20,
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h4_1900,
    },
  },

  horizontal_card_block__right_part: {
    marginBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
      marginBottom: 112,
    },
    [theme.breakpoints.up('bp_768')]: {
      paddingLeft: 24,
    },
  },

  horizontal_card_block__right_part_text: {
    ...theme.typography.P2_R_900,
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.P2_R_1900,
    },
  },

  horizontal_card_block__container: {
  },

  horizontal_card_block__all_cards: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.up('bp_560')]: {
      // marginLeft: '50%',
    },
  },

  horizontal_card_block__wrap_card: {
    width: '100%',
    // marginRight: 24,
    // marginLeft: 'auto',
    marginBottom: 24,
    [theme.breakpoints.up('bp_560')]: {
      // marginRight: 48,
    },
    [theme.breakpoints.up('bp_768')]: {
      width: '50%',
      marginBottom: 48,
    },
    [theme.breakpoints.up('bp_1440')]: {
      width: 'auto',
      marginRight: 48,
    },
    [theme.breakpoints.up('bp_1900')]: {
      // marginRight: 64,
    },
    // '&:first-child': {
    //   [theme.breakpoints.up('bp_1440')]: {
    //     marginLeft: 'calc(50vw - 95px)',
    //   },
    // },
    '&:nth-child(odd)': {
      [theme.breakpoints.up('bp_768')]: {
        paddingRight: 24,
      },
      [theme.breakpoints.up('bp_1440')]: {
        paddingRight: 0,
      },
    },
    '&:nth-child(even)': {
      [theme.breakpoints.up('bp_768')]: {
        paddingLeft: 24,
      },
      [theme.breakpoints.up('bp_1440')]: {
        paddingLeft: 0,
      },
    },
    '&:nth-child(2)': {
      [theme.breakpoints.up('bp_1440')]: {
        marginRight: 0,
      },
    },
    '&:first-child': {
      [theme.breakpoints.up('bp_1440')]: {
        marginLeft: 'calc(50% + 24px)',
      },
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },

  
}));

export default useStylesHorizontalCardBlock;
