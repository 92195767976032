import React from 'react';

import sprite from '../../../assets/images/sprite.svg';

const IconUI = ({
  name = '',
  className = '',
}) => {
  return (
    <svg className={className}>
      <use xlinkHref={`${sprite}#${name}`} />
    </svg>
  );
};

export default IconUI;
