import { makeStyles } from '@mui/styles';

const useStylesFooter = makeStyles((theme) => ({
  footer: {
    padding: '24px 0',
    borderTop: `1px solid ${theme.palette.white}`,
    [theme.breakpoints.up('bp_900')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('bp_1900')]: {
      padding: '32px 0',
    },
  },

  footer__left_part: {
    [theme.breakpoints.up('bp_900')]: {
      width: '50%',
    },
  },

  footer__right_part: {
    [theme.breakpoints.up('bp_900')]: {
      width: '50%',
    },
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center',
  },

  footer__copy_right: {
    ...theme.typography.P2_R_900,
    marginBottom: 24,
    [theme.breakpoints.up('bp_560')]: {
      marginBottom: 48,
    },
    [theme.breakpoints.up('bp_900')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.P2_R_1900,
    },
  },

  footer__big_cell: {
    width: '100%',
    [theme.breakpoints.up('bp_560')]: {
      display: 'flex',
      marginBottom: 64,
    },
  },

  footer__cell: {
    width: '100%',
    marginBottom: 24,
    '&:first-child': {
      paddingRight: 20,
    },
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
      marginBottom: 0,
    },
  },

  footer__cell_title: {
    ...theme.typography.P2_M_900,
    marginBottom: 16,
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.P2_M_1900,
      marginBottom: 22,
    },
  },

  footer__cell_description: {
    ...theme.typography.P2_R_900,
    color: theme.palette.black_60,
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.P2_R_1900,
    },
    '& a': {
      ...theme.typography.P2_R_900,
      color: theme.palette.black_60,
      [theme.breakpoints.up('bp_1900')]: {
        ...theme.typography.P2_R_1900,
      },
    },
  },

  footer__wrap_last_words: {
    display: 'flex',
    flexFlow: 'column',
    [theme.breakpoints.up('bp_560')]: {
      flexFlow: 'row',
    },
  },

  footer__last_word: {
    ...theme.typography.P2_R_900,
    color: theme.palette.white,
    [theme.breakpoints.up('bp_560')]: {
      marginRight: 32,
    },
    [theme.breakpoints.up('bp_1900')]: {
      marginRight: 42,
      ...theme.typography.P2_R_1900,
    },
    '&:first-child': {
      marginBottom: 16,
      [theme.breakpoints.up('bp_560')]: {
        marginBottom: 0,
      },
    },
  },



}));

export default useStylesFooter;
