import React from 'react';

import IconUI from '../UI/IconUI/IconUI';

import useStylesPreloader from './PreloaderStyles';

const Preloader = () => {
  const classes = useStylesPreloader();

  return (
    <div className={`${classes.preloader} preloader`}>
      <div className={`${classes.preloader__wrap_elem} wrap_elem`}>
        <svg xmlns="http://www.w3.org/2000/svg" className="wrap-svg-circle" version="1.1" viewBox="0 0 96 96">
          <circle className="circle__background" cx="48" cy="48" r="47" stroke="#2C2C2C" strokeWidth="2" fill="rgba(0,0,0,0)"/>
          <circle className="svg-circle" cx="48" cy="48" r="47" stroke="#EE443A" strokeWidth="2"  fill="rgba(0,0,0,0)"/>
        </svg>

        <div className={classes.preloader__icon_logo}>
          <IconUI name="icon_logo"/>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
