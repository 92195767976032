import { makeStyles } from '@mui/styles';

const useStylesNotFoundSection = makeStyles((theme) => ({
  not_found_section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('bp_561')]: {
      // minHeight: 'calc(100vh - 57px)',
      // height: '-webkit-fill-available',
    },
  },
  not_found_num: {
    marginTop: '32px',
    color: 'white',
    fontSize: '80px',
    lineHeight: '80px',
    fontWeight: 600,
    [theme.breakpoints.down('bp_561')]: {
      marginTop: '40px',
    },
  },
  not_found_text: {
    color: '#7A7A7A',
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 400,
    marginTop: '16px',
    [theme.breakpoints.down('bp_561')]: {
      fontSize: '18px',
      lineHeight: '24px',
      marginBottom: '16px',
    },
  },
  return__btn: {
    overflow: 'hidden',
    width: '273px',
    marginTop: '80px',
    [theme.breakpoints.down('bp_561')]: {
      width: 'calc(100% - 48px)',
      position: 'absolute',
      bottom: '18px',
      left: 24,
      marginTop: '0',
      // marginBottom: '24px',
    },
    '&>button': {
      fontSize: '22px',
      lineHeight: '24px',
      height: '56px !important',
      borderRadius: '40px !important',
    },
  },
  animation__container: {
    width: '338px',
    height: '338px',
    position: 'relative',
    marginTop: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'auto 240px',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='186' height='240' viewBox='0 0 186 240' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M62.8059 194.071L62.8139 194.08C71.8841 203.6 82.8101 208.371 95.5655 208.371C109.166 208.371 120.314 203.606 128.967 194.071L128.971 194.067C137.614 184.325 141.924 172.1 141.924 157.43C141.924 143.187 137.721 131.179 129.295 121.44C120.853 111.681 109.592 106.809 95.5655 106.809C81.7536 106.809 70.6018 111.573 62.16 121.114C53.7286 130.644 49.5276 142.763 49.5276 157.43C49.5276 172.103 53.9467 184.33 62.806 194.071L62.8059 194.071ZM35.925 101.261L36.5999 100.673L35.7453 100.406C26.2349 97.4419 17.9789 91.5113 10.978 82.5853C3.98636 73.4572 0.5 63.4966 0.5 52.6908C0.5 37.8421 6.10307 25.4474 17.3286 15.4742C28.5625 5.49355 42.125 0.5 58.0448 0.5C68.1989 0.5 80.8407 4.10615 95.9906 11.3745L95.9906 11.3746L95.9983 11.3781C103.704 14.9163 110.216 17.5749 115.53 19.3497C120.835 21.1211 124.986 22.0261 127.955 22.0261C134.06 22.0261 139.21 20.0557 143.372 16.1056L143.372 16.1051C147.645 12.0389 149.828 6.82358 149.937 0.5H185.498C185.388 15.7849 180.198 28.336 169.94 38.1937L169.936 38.1973C159.789 48.151 145.817 53.1546 127.955 53.1546C117.8 53.1546 105.265 49.6546 90.3312 42.6019C82.9455 38.956 76.5654 36.2158 71.194 34.3861C65.8284 32.5584 61.4388 31.6285 58.0448 31.6285C51.7397 31.6285 46.4844 33.4833 42.318 37.218C38.1311 40.9712 36.0586 46.1507 36.0586 52.6908C36.0586 60.4307 38.8435 66.2994 44.4502 70.2093C50.0241 74.0964 58.3228 76.002 69.269 76.002H95.5655C119.39 76.002 138.926 83.7754 154.216 99.3066L154.219 99.309C169.51 114.629 177.162 133.989 177.162 157.43C177.162 180.221 169.407 199.587 153.893 215.555C138.385 231.517 118.955 239.5 95.5655 239.5C72.3896 239.5 52.9573 231.624 37.2348 215.873C21.7255 200.122 13.969 180.652 13.969 157.43C13.969 132.686 21.3103 113.993 35.925 101.261Z' stroke='%233A3A3A'/%3E%3C/svg%3E")`,

    [theme.breakpoints.down('bp_561')]: {
      width: '244px',
      height: '244px',
      backgroundSize: 'auto 180px',
    },
  },
  head_anim__symbol: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maskRepeat: 'no-repeat',
    maskPosition: 'center',
    maskSize: 'auto 240px',
    maskImage: `url("data:image/svg+xml,%3Csvg width='186' height='240' viewBox='0 0 186 240' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M62.5345 121.446C54.1966 130.87 50.0276 142.865 50.0276 157.43C50.0276 171.995 54.4103 184.096 63.1759 193.735C72.1552 203.159 82.9517 207.871 95.5655 207.871C109.034 207.871 120.045 203.159 128.597 193.735C137.148 184.096 141.424 171.995 141.424 157.43C141.424 143.293 137.255 131.406 128.917 121.767C120.579 112.129 109.462 107.309 95.5655 107.309C81.8828 107.309 70.8724 112.021 62.5345 121.446ZM35.5966 100.884C25.9759 97.8849 17.6379 91.8876 10.5828 82.8916C3.52759 73.6814 0 63.6144 0 52.6908C0 37.6974 5.66552 25.1673 16.9966 15.1004C28.3276 5.03347 42.0103 0 58.0448 0C68.3069 0 81.0276 3.64123 96.2069 10.9237C111.6 17.992 122.183 21.5261 127.955 21.5261C133.941 21.5261 138.966 19.5984 143.028 15.743C147.303 11.6734 149.441 6.4257 149.441 0H186C186 15.6359 180.762 28.4873 170.286 38.5542C160.024 48.6212 145.914 53.6546 127.955 53.6546C117.693 53.6546 105.079 50.1205 90.1138 43.0522C75.3621 35.7697 64.6724 32.1285 58.0448 32.1285C51.8448 32.1285 46.7138 33.9491 42.6517 37.5904C38.5897 41.2316 36.5586 46.2651 36.5586 52.6908C36.5586 67.8983 47.4621 75.502 69.269 75.502H95.5655C119.51 75.502 139.179 83.3199 154.572 98.9558C169.966 114.378 177.662 133.869 177.662 157.43C177.662 180.348 169.859 199.839 154.252 215.904C138.645 231.968 119.083 240 95.5655 240C72.2621 240 52.7 232.075 36.8793 216.225C21.2724 200.375 13.469 180.776 13.469 157.43C13.469 132.584 20.8448 113.735 35.5966 100.884Z' fill='%23EE443A'/%3E%3C/svg%3E")`,
    [theme.breakpoints.down('bp_561')]: {
      maskSize: 'auto 180px',
    },
  },
  head_anim__mask: {
    position: 'absolute',
    top: '0px',
    left: '160px',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    // border: '1px solid rgba(255,255,255,0.3)',
    background: '#EE443A',
    animation: `$notFoundLogo 4000ms ${theme.transitions.easing.sharp} infinite alternate`,
    [theme.breakpoints.down('bp_561')]: {
      left: '110px',
      animation: `$notFoundLogoMobile 4000ms ${theme.transitions.easing.sharp} infinite alternate`,
    },
  },
  head_anim__mask_border: {
    position: 'absolute',
    top: 0,
    left: '160px',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    border: '1px solid #3A3A3A',
    animation: `$notFoundLogo 4000ms ${theme.transitions.easing.sharp} infinite alternate`,
    [theme.breakpoints.down('bp_561')]: {
      left: '110px',
      animation: `$notFoundLogoMobile 4000ms ${theme.transitions.easing.sharp} infinite alternate`,
    },
  },
  "@keyframes notFoundLogo": {
    "0%": {
      left: '160px',
    },
    "100%": {
      left: '-160px',
    },
  },
  "@keyframes notFoundLogoMobile": {
    "0%": {
      left: '110px',
    },
    "100%": {
      left: '-110px',
    },
  },
}));

export default useStylesNotFoundSection;
