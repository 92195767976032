import { makeStyles } from '@mui/styles';

const useStylesLayout = makeStyles((theme) => ({
  layout: {
    width: '100%',
    maxWidth: 1920,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    margin: '0 auto',
  },

  layout__wrap_header: {
    background: theme.palette.black_100,
  },

  layout__sticky_box: {
    zIndex: 1000,
    background: theme.palette.black_100,
  },

  layout__sticky_box_for_btn: {
    opacity: 0,
    transform: 'translate3d(0, 50px, 0)',
    zIndex: 100,
    width: '100%',
    [theme.breakpoints.up('bp_560')]: {
      width: 286,
    },
    [theme.breakpoints.up('bp_900')]: {
      width: 390,
    },
    [theme.breakpoints.up('bp_1900')]: {
      width: 413,
    },
  },

  layout__content: {
    flexGrow: '1',
  },

  layout__btn: {
    width: 222,
    paddingBottom: 20,
    [theme.breakpoints.up('bp_560')]: {
      width: 238,
      paddingBottom: 24,
    },
    [theme.breakpoints.up('bp_1900')]: {
      width: 318,
      paddingBottom: 34,
    },
    '&.long_big_btn': {
      width: '100%',
      [theme.breakpoints.up('bp_560')]: {
        width: 238,
        paddingBottom: 24,
      },
      [theme.breakpoints.up('bp_1900')]: {
        width: 318,
        paddingBottom: 34,
      },
    },
  },

   
  '@global': {

    // показывает кнопку ОБСУДИТЬ ПРОЕКТ
    '.show_big_btn': {
      animation:' showBigBtn 1s linear forwards 0.7s',
    },

    '@keyframes showBigBtn': {
      'from': {
        opacity: 0,
        transform: 'translate3d(0, 50px, 0)',
      },
      'to': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      },
    },
    // показывает кнопку ОБСУДИТЬ ПРОЕКТ


    // показывает шапку
    '.show_header': {
      animation:' showHeader 1s linear forwards',
    },

    '@keyframes showHeader': {
      'from': {
        opacity: 0,
        transform: 'translate3d(0, -50px, 0)',
      },
      'to': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      },
    },
    // показывает шапку
  },
}));

export default useStylesLayout;
