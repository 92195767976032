import React, {useState, useEffect} from 'react';

import ButtonUI from '../UI/ButtonUI/ButtonUI';
import useResize from '../../helpers/useResize';

import useStylesTicker from './TickerStyles';

const Ticker = ({
  text = 'Пусто',
  textHovered = 'Пусто',
  descriptionHovered = 'Пусто',
  direction = 'right',
  onClickArrow = () => { },
}) => {
  const classes = useStylesTicker();
  const [, setShow] = useState(false);
  const width = useResize();
  const [, setIsMobileSize] = useState(false);


  useEffect(() => {
    setIsMobileSize(width < 1300);
  }, [width]);

  return (
    <div
      className={`${classes.ticker}`}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      onClick={onClickArrow}
    >
      <div className={classes.ticker__wrap_new}>
        <h2 dangerouslySetInnerHTML={{ __html: textHovered }} />
        <p dangerouslySetInnerHTML={{ __html: descriptionHovered }} />
        <div
          className={`${classes.ticker__wrap_icon_down_arr__new} ticker__wrap_icon_down_arr__new__static`}
          onClick={onClickArrow}
        >
          <ButtonUI
            typeBtn="circle-next"
          />
        </div>
      </div>
    </div>
  );
};

export default Ticker;
