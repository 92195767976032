import { makeStyles } from '@mui/styles';

const useStylesHeaders = makeStyles((theme) => ({
  headers: {
    // borderTop: `1px solid ${theme.palette.white}`,
    // paddingTop: 24,
    // paddingBottom: 48,
    // [theme.breakpoints.up('bp_560')]: {
    //   paddingBottom: 128,
    // },
    // [theme.breakpoints.up('bp_1900')]: {
    //   paddingTop: 32,
    //   paddingBottom: 170,
    // },
  },

  headers__wrap_nums: {
    width: '50%',
    marginLeft: 'auto',
    overflow: 'hidden',
    paddingBottom: 20,
  },

  headers__wrap_title: {
    marginBottom: 48,
    [theme.breakpoints.up('bp_1900')]: {
      marginBottom: 64,
    },

    '&.first': {
      '& > div:last-child': {
        textAlign: 'right',
      },
    },
    '&.second': {
      '& > div:nth-child(1)': {
        textAlign: 'right',
      },
      '& > div:nth-child(3)': {
        textAlign: 'right',
      },
      '& > div:nth-child(4)': {
        textAlign: 'right',
      },
    },
    '&.third': {
      '& > div:nth-child(2)': {
        textAlign: 'right',
      },
    },
  },

  headers__text: {
    ...theme.typography.h1,
    [theme.breakpoints.up('bp_768')]: {
      ...theme.typography.h1_560,
    },
    [theme.breakpoints.up('bp_1280')]: {
      ...theme.typography.h1_900,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h1_1900,
    },
  },

  headers__hidden_block: {
    // overflow: 'hidden',
  },

}));

export default useStylesHeaders;
