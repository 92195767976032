import { makeStyles } from '@mui/styles';

const useStylesTextAreaUI = makeStyles((theme) => ({
  text_area_ui: {
    width: '100%',
    outline: 'none',
    resize: 'none', 
    border: 'none',
    paddingTop: 10,
    paddingLeft: 0,
    borderBottom: `1px solid ${theme.palette.black_80}`,
    background: 'transparent',
    ...theme.typography.P2_R_900,
    color: theme.palette.white,
    height: 40,
    '&:disabled': {
      // borderBottom: `1px solid ${theme.palette.black_100}`,
      // background: theme.palette.black_60,
    },
    '&:hover': {
      // borderBottom: `1px solid ${theme.palette.red}`,
    },
    '&:focus': {
      borderBottom: `1px solid ${theme.palette.white}`,
      'caret-color': theme.palette.red,
    },
    '&.error': {
      borderBottom: `1px solid ${theme.palette.red}`,
      color: theme.palette.red,
    },

    '&::-webkit-input-placeholder': {
      color: `${theme.palette.black_80} !important`,
    },
    '&::-moz-placeholder': {
      color: `${theme.palette.black_80} !important`,
    },
    '&:-moz-placeholder': {
      color: `${theme.palette.black_80} !important`,
    },
    '&:-ms-input-placeholder': {
      color: `${theme.palette.black_80} !important`,
    },
  },

}));

export default useStylesTextAreaUI;
