import { actionTypes } from '../types';

const initialState = {
  modal: {
    bool: false,
    typeModal: '',
  },
  toggleSidebar: false,
  isSend: false,
  recaptchaSiteKey: '',
  errorMessage: [],
};

function commonReducer(state = initialState, action) {
  switch (action.type) {

  case actionTypes.MODAL:
    return {
      ...state,
      modal: {
        typeModal: action.typeModal,
        bool: action.bool,
      },
      errorMessage: [],
    };

  case actionTypes.GET_RECAPTCHA_SUCCESS:
    return {
      ...state,
      recaptchaSiteKey: action.data.recaptchaSiteKey,
    };
  case actionTypes.SEND_DISCUSS_PROJECT:
    return {...state, errorMessage: []};
  case actionTypes.SEND_DISCUSS_PROJECT_ERROR:
    return {...state, errorMessage: action.data.errorMessage};
  case actionTypes.IS_SEND_DISCUSS_PROJECT:
    return {
      ...state,
      isSend: action.bool,
    };
    // case actionTypes.SEND_DISCUSS_PROJECT:
    //   return {
    //     ...state,
    //   };
    // case actionTypes.SEND_DISCUSS_PROJECT_SUCCESS:
    //   return {
    //     ...state,
    //   };

  default:
    return state;
  }
}

export default commonReducer;
