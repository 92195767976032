import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import IconUI from '../UI/IconUI/IconUI';
import { modalAction } from '../../store/actions/commonActions';

import { modalSelector } from '../../store/selectors/commonSelectors';

import useStylesHeader from './HeaderStyles';

const Header = ({
  modalAction = () => {},
  modal = {},
}) => {
  const classes = useStylesHeader();
  const { typeModal } = modal;

  const toRootPage = () => {
    window.scrollTo({top: 0});
    modalAction(typeModal, false);
    setTimeout(() => {
      modalAction('', false);
    }, 500);
  };

  return (
    <div className={classes.header}>
      <div className={classes.header__left_part}>
        <Link to='/' onClick={() => toRootPage()} style={{color: 'inherit'}}>
          <h5 className={classes.header__logo}>
            <span>бюро</span>&nbsp;
            <span>поддержка</span>
          </h5>
        </Link>
      </div>

      <div className={classes.header__right_part}>
        <a href="mailto:info@buroburo.ru" className={classes.header__mail}>
          <div className={classes.header__mail_text}>
            info@buroburo.ru
          </div>

          <div className={classes.header__wrap_icon_mail}>
            <IconUI name="icon_mail"/>
          </div>
        </a>

        {(modal?.typeModal === 'menu')
          && (
            <div
              className={classes.header__wrap_icon_close}
              onClick={() => {
                modalAction('menu', false);
                // очищаем состояние
                setTimeout(() => modalAction('', false), 500);
              }}
            >
              <IconUI name="icon_close"/>
            </div>
          )}

        {(modal?.typeModal === '')
          && (
            <div
              className={classes.header__wrap_icon_menu}
              onClick={() => modalAction('menu', true)}
            >
              <IconUI name="icon_menu"/>
            </div>
          )}

      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  modal: modalSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  modalAction: (bool, typeModal) => dispatch(modalAction(bool, typeModal)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Header);
