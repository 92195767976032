import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// import Lenis from '@studio-freight/lenis'; // для плавного скролла

import { ParallaxProvider } from 'react-scroll-parallax';

import { scroller } from 'react-scroll';

import Layout from './layout/layout';
import MainContainer from './containers/MainContainer/MainContainer';
import ModalUI from './components/ModalUI/ModalUI';
// import Preloader from './components/Preloader/Preloader';

import { theme } from './layout/theme';

// для плавного скролла ВНИМАНИЕ (отключает функциональную часть пакета scroll-lock (для скрытия полосы скролла когда модалка открывается))
// НО визуально скролл пропадает, так что не так уж и страшно
// const lenis = new Lenis({
//   duration: 2, // speed (плавность скролла)
//   // easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
//   // direction: "vertical", // vertical, horizontal
//   // gestureDirection: "vertical", // vertical, horizontal, both
//   // smooth: true,
//   // mouseMultiplier: 0.5, // sensibility
//   // smoothTouch: false, // Mobile
//   // touchMultiplier: 2, // sensibility on mobile
//   // infinite: false, // Infinite scrolling
// });

// // lenis.on('scroll', (e) => {
// //   console.log(e);
// // });
// function raf(time) {
//   lenis.raf(time);
//   requestAnimationFrame(raf);
// }
// requestAnimationFrame(raf);


function App() {

  // Сделаем плавный скролл для #-якорей ровно один раз при первом рендере
  useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => {
        scroller.scrollTo(window.location.hash.substring(1), {
          duration: 0,
          delay: 0,
          offset: -73,
          smooth: 'easeInOutQuart',
        });
        window.history.pushState('', window.document.title, window.location.pathname + window.location.search);
      }, 2425);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <ParallaxProvider>
        <Layout>
          <MainContainer/>
        </Layout>
      </ParallaxProvider>

      <ModalUI />

      {/*<Preloader />*/}
    </ThemeProvider>
  );
}

export default App;
