import React from 'react';

import IconUI from '../IconUI/IconUI';

import useStylesContainerFormElement from './ContainerFormElementStyles';

const ContainerFormElement = function ({
  children,
  textLabel = '',
  className = '',
  classNameInner = '',
  necessarily = false,
  textBottom = '',
}) {
  const classes = useStylesContainerFormElement();

  return (
    <div className={`${classes.container_form_element} ${className}`}>
      <div className={`${classes.container_form_element__wrap_label} ${classNameInner}`}>
        <div className={classes.container_form_element__label}>{textLabel}</div>
        {necessarily && (
          <IconUI
            name="icon_red_star"
            className={classes.container_form_element__red_star}
          />
        )}
      </div>
      {children}
      {textBottom && (
        <div className={classes.container_form_element__dop_text}>{textBottom}</div>
      )}
    </div>
  );
};

export default ContainerFormElement;
