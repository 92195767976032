import { makeStyles } from '@mui/styles';

const useStylesSection = makeStyles((theme) => ({
  section: {
    overflow: 'hidden',
    padding: '0 24px',
    [theme.breakpoints.up('bp_561')]: {
      padding: '0 48px',
    },
    [theme.breakpoints.up('bp_900')]: {
      padding: '0 72px',
    },
    [theme.breakpoints.up('bp_1900')]: {
      padding: '0 95px',
    },
  },
}));

export default useStylesSection;
