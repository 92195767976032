import { makeStyles } from '@mui/styles';

const useStylesBitrixSection = makeStyles((theme) => ({
  bitrix_section: {
  },

  bitrix_section__line: {
    borderTop: `1px solid ${theme.palette.white}`,
    paddingTop: 24,
    paddingBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      paddingBottom: 128,
    },
    [theme.breakpoints.up('bp_1900')]: {
      paddingTop: 32,
      paddingBottom: 170,
    },
  },

  bitrix_section__not_line: {
    paddingTop: 24,
    paddingBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      paddingBottom: 128,
    },
    [theme.breakpoints.up('bp_1900')]: {
      paddingTop: 32,
      paddingBottom: 170,
    },
  },

  bitrix_section__wrap_nums: {
    width: '50%',
    marginLeft: 'auto',
  },

  bitrix_section__wrap_title: {
    marginBottom: 48,
    [theme.breakpoints.up('bp_1900')]: {
      marginBottom: 64,
    },
    '& div': {
      ...theme.typography.h1,
      [theme.breakpoints.up('bp_768')]: {
        ...theme.typography.h1_560,
      },
      [theme.breakpoints.up('bp_1280')]: {
        ...theme.typography.h1_900,
      },
      [theme.breakpoints.up('bp_1900')]: {
        ...theme.typography.h1_1900,
      },
    },
    '& div:nth-child(1)': {
      textAlign: 'left',
    },
    '& div:nth-child(2)': {
      textAlign: 'right',
    },
  },

  bitrix_section__wrap_text: {
    marginLeft: 'auto',
    ...theme.typography.h2,
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h2_560,
    },
    [theme.breakpoints.up('bp_900')]: {
      width: '50%',
      ...theme.typography.h2_900,
      paddingLeft: 24,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h2_1900,
    },
  },

  bitrix_section__wrap_nobp: {
    whiteSpace: 'nowrap',
  },

  bitrix_section__only_line: {
    textAlign: 'center',
    ...theme.typography.h2,
    color: theme.palette.red,
    marginBottom: 16,
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h2_560,
    },
    [theme.breakpoints.up('bp_900')]: {
      ...theme.typography.h2_900,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h2_1900,
      marginBottom: 22,
    },
  },

  bitrix_section__second_end_big_text: {
    textAlign: 'center',
    maxWidth: 1072,
    margin: '0 auto',
    marginBottom: 48,
    ...theme.typography.h2,
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h2_560,
      marginBottom: 112,
    },
    [theme.breakpoints.up('bp_900')]: {
      ...theme.typography.h2_900,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h2_1900,
      marginBottom: 170,
    },
  },


}));

export default useStylesBitrixSection;
