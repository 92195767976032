import { createTheme } from '@mui/material/styles';

import HarmoniaSansProCyrBold from '../assets/fonts/HarmoniaSansProCyr-SemiBd.woff2';
import HarmoniaSansProCyrRegular from '../assets/fonts/HarmoniaSansProCyr-Regular.woff2';
import HarmoniaSansProCyrBureau from '../assets/fonts/HarmoniaBureauSansProCyr.woff2';

import CoveredByYourGrace from '../assets/fonts/CoveredByYourGrace.woff2';

const palette = {
  black_100: '#212121',
  black_95: '#2C2C2C',
  black_90: '#383838',
  black_80: '#4D4D4D',
  black_70: '#646464',
  black_60: '#7A7A7A',
  white: '#FFFFFF',
  red: '#EE443A',
  red_medium: '#E2382E',
  red_light: '#FF5D54',
};

const breakpoints = {
  // брекпоинты xs: false, sm: false, md: false, lg: false, xl: false, НЕ используются в проекте
  // тем не менее их нужно прописать и установить false, иначе на хроме 88 версии,
  // а может быть еще где-то, проект выдает ошибку
  // Failed to execute 'px' on 'CSS': The provided double value is non-finite
  values: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    bp_560: 560,
    bp_561: 561,
    bp_767: 767,
    bp_768: 768,
    bp_900: 900,
    bp_1200: 1200,
    bp_1280: 1280,
    bp_1440: 1440,
    bp_1900: 1900,
  },
};

const typography = {
  fontFamily: 'HarmoniaSansProCyr, HarmoniaBureauSansProCyr, CoveredByYourGrace, sans-serif',
  h1: {
    // позволяет использовать медиазапросы
    // '@media (min-width:560px)': {
    //   fontSize: '88px',
    //   lineHeight: '80px',
    // },
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '400',
    fontSize: '42px',
    lineHeight: '40px',
  },
  h1_560: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '400',
    fontSize: '88px',
    lineHeight: '80px',
  },
  h1_900: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '400',
    fontSize: '136px',
    lineHeight: '120px',
  },
  h1_1900: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '400',
    fontSize: '180px',
    lineHeight: '160px',
  },

  h2: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '28px',
  },
  h2_560: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '38px',
    lineHeight: '40px',
  },
  h2_900: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '44px',
    lineHeight: '48px',
  },
  h2_1900: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '59px',
    lineHeight: '64px',
  },

  h3: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '24px',
  },
  h3_900: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '32px',
  },
  h3_1900: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '42px',
    lineHeight: '42px',
  },
  h3_tab: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '28px',
  },

  h3_mini: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '20px',
  },
  h3_mini_900: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '32px',
  },
  h3_mini_1900: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '42px',
    lineHeight: '42px',
  },

  h4: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '24px',
  },
  h4_1900: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '32px',
  },

  h5: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '24px',
  },
  h5_1900: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
  },

  h6: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '16px',
  },
  h6_1900: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '22px',
  },

  P1_R: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
  },
  P1_R_900: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '400',
    fontSize: '22px',
    lineHeight: '28px',
  },

  P1_M: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '24px',
  },
  P1_M_900: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '28px',
  },

  P2_R_900: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
  },
  P2_R_1900: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '32px',
  },

  P2_M_900: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '24px',
  },
  P2_M_1900: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
  },

  P3_R_900: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
  },

  P3_M_900: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
  },

  P4_R_900: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
  },

  P4_M_900: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
  },

  P5_R_900: {
    fontFamily: 'HarmoniaSansProCyr',
    fontWeight: '600',
    fontSize: '10px',
    lineHeight: '12px',
  },

  acid: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '40px',
    lineHeight: '40px',
  },
  acid_900: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '80px',
    lineHeight: '80px',
  },
  acid_1900: {
    fontFamily: 'HarmoniaBureauSansProCyr',
    fontWeight: '600',
    fontSize: '107px',
    lineHeight: '107px',
  },

  forCapcha: {
    fontFamily: 'Covered By Your Grace',
    fontWeight: '400',
    fontSize: '26px',
    lineHeight: '24px',
  },
};

export const theme = createTheme({
  palette,
  breakpoints,
  typography,

  // ВНИМАНИЕ --- ЗАПЯТЫЕ в styleOverrides после каждого объекта не ставить
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'HarmoniaSansProCyr';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('HarmoniaSansProCyr'), local('HarmoniaSansProCyr'), url(${HarmoniaSansProCyrBold}) format('woff2');
        }
        @font-face {
          font-family: 'HarmoniaSansProCyr';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('HarmoniaSansProCyr'), local('HarmoniaSansProCyr'), url(${HarmoniaSansProCyrRegular}) format('woff2');
        }
        @font-face {
          font-family: 'HarmoniaBureauSansProCyr';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('HarmoniaBureauSansProCyr'), local('HarmoniaBureauSansProCyr'), url(${HarmoniaSansProCyrBureau}) format('woff2');
        }
        @font-face {
          font-family: 'Covered By Your Grace';
          font-style: normal;
          font-display: swap;
          font-weight: normal;
          src: local('Covered By Your Grace'), local('Covered By Your Grace'), url(${CoveredByYourGrace}) format('woff2');
        }
        * {
          box-sizing: border-box;
        }
				body {
					background-color: ${palette.black_100};
					color: ${palette.white};
					font-size: inherit;
					letter-spacing: inherit;
					line-height: inherit;
				}
        a
        h1, h2, h3, h4, h5, h6 {
					margin: 0;
				}
				p {
					margin: 0;
				}
				a {
					text-decoration: none;
				}
				button {
					cursor: pointer;
					padding: 0;
					outline: none;
					border: none;
					font-family: 'HarmoniaSansProCyr';
          background: inherit;
				}
        input {
          border: none;
          outline: none;
          font-family: 'HarmoniaSansProCyr';
        }
      `,
    },
  },
});