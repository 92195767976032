import React, {useLayoutEffect, useRef} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Helmet} from 'react-helmet';

import gsap from 'gsap'; // для маневров с карточками
import {ScrollTrigger} from 'gsap/ScrollTrigger'; // для маневров с карточками

import Headers from '../Headers/Headers';
import HorizontalCardBlock from '../HorizontalCardBlock/HorizontalCardBlock';
import {
  dataHorizontalCards,
  dataHorizontalCardsSecond,
  dataListCards,
  dataServicesCards,
  dataFirstSectionBigCards,
} from '../../helpers/data-helper';
import ServicesCard from '../ServicesCard/ServicesCard';
import BigCardBlock from '../BigCardBlock/BigCardBlock';
import ListCard from '../ListCard/ListCard';
import useResize from '../../helpers/useResize';

import useStylesSupportSection from './SupportSectionStyles';


gsap.registerPlugin(ScrollTrigger); // для маневров с карточками

const SupportSection = () => {
  const classes = useStylesSupportSection();
  const minWidth1280 = useMediaQuery('(min-width:1280px)');
  const width = useResize(0);


  // прикрепляем текст к верху экрана в области секции при скролле (Зачем компании покупать услугу технической поддержки)
  const wrapSection = useRef();
  const target = useRef();
  const endSection = useRef();
  useLayoutEffect(() => {
    if (minWidth1280) {
      const ctx = gsap.context(() => {
        const heightSection = wrapSection.current.getBoundingClientRect().height;
        const endheightSection = endSection.current.getBoundingClientRect().height;

        ScrollTrigger.create({
          trigger: target.current,
          start: `top-=${100} top`,
          end: () => '+=' + (heightSection - (endheightSection + 400)),
          pin: true,
          pinSpacing: false,
          // markers: true,
        });

      }, wrapSection);
      return () => ctx.revert();
    }
  }, [width, minWidth1280]);

  // прикрепляем текст к верху экрана в области секции при скролле ()
  const wrapSection2 = useRef();
  const target2 = useRef();
  useLayoutEffect(() => {
    if (minWidth1280) {
      const ctx = gsap.context(() => {
        const heightSection2 = wrapSection2.current.getBoundingClientRect().height;

        ScrollTrigger.create({
          trigger: target2.current,
          start: `top-=${100} top`,
          end: () => '+=' + (heightSection2 - 500),
          pin: true,
          pinSpacing: false,
          // markers: true,
        });

      }, wrapSection2);
      return () => ctx.revert();
    }
  });

  return (
    <>
      <Helmet>
        <title>Техподдержка сайтов и бесперебойная работа сервисов 24/7 - БюроБюро</title>
        <meta name="description" content="БюроБюро обеспечивает техподдержку сайтов и бесперебойную работу вашего проекта с гарантированной реакцией на инциденты в течение 15 минут."/>
        <meta name="keywords" content="Техподдержка сайта, техническая поддержка сайта, услуги технической поддержки сайта"/>
      </Helmet>
      <div className={classes.support_section}>
        <div className={classes.support_section__not_line}>
          <Headers
            section="first"
            nums={['01', '03']}
            words={['круглосуточная', 'техподдержка']}
          />

          <div className={classes.support_section__wrap_text}>
            бесперебойная работа вашего проекта и гарантированная реакция на инциденты в течение 15 минут
          </div>
        </div>

        {/*  */}

        <div className={classes.support_section__line} ref={wrapSection}>
          <div className={classes.support_section__container}>
            <div className={classes.support_section__container_left_part} ref={target}>
              Зачем компании покупать <br></br> услугу технической <br></br> поддержки
            </div>

            <div className={classes.support_section__container_right_part}>
              <div className={classes.support_section__container_small_text}>
                Техподдержка – гарантия бесперебойной работы сервисов 24/7 и, как следствие, минимизации издержек бизнеса, связанных с простоями из-за технических проблем.
                <br></br>
                <br></br>
                Специалисты мониторят состояние и работоспособность серверов и программ, своевременно фиксируют возникающие проблемы и либо
                устраняют их, либо эскалируют проблему до ответственных сотрудников компании в соответствии с уровнем поддержки.
              </div>
            </div>
          </div>

          <div className={classes.support_section__big_text}>
            По нашему опыту, потребность в услугах внешней технической поддержки чаще всего возникает в следующих случаях:
          </div>

          <div className={classes.support_section__all_cards}>
            {dataServicesCards?.map((item, index) =>
              <React.Fragment key={index}>
                <ServicesCard item={item} num={index + 1}/>
              </React.Fragment>,
            )}
          </div>

          <div className={classes.support_section__only_line}>~</div>

          <div className={classes.support_section__second_big_text} ref={endSection}>
            Услуга технической поддержки 24/7 позволяет компании больше думать о развитии бизнеса,
            чем о поддержании работоспособности существующей инфраструктуры, а разные уровни и
            дополнительные опции позволяют тонко настраивать услугу и безболезненно масштабировать проект
          </div>
        </div>

        {/*  */}

        <div>
          <BigCardBlock data={dataFirstSectionBigCards}/>
        </div>

        {/*  */}

        <div className={classes.support_section__line} ref={wrapSection2}>
          <div className={classes.support_section__container_levels}>
            <div className={classes.support_section__container_levels_left_part} ref={target2}>
              Три уровня поддержки
            </div>


            <div className={classes.support_section__container_levels_right_part}>
              <div className={classes.support_section__container_levels_text}>
                Мы предлагаем три уровня технической поддержки, различающиеся глубиной интеграции нашей команды.
                В зависимости от ваших запросов, вы можете подключить только первую линию поддержки, первую и вторую линию,
                или все три линии техподдержки. Вне зависимости от выбранного уровня мы гарантируем реакцию на инциденты в течение максимум 15 минут.
              </div>
            </div>
          </div>

          <div className={classes.support_section__container_levels_all_cards}>
            <div className={classes.support_section__container_levels_wrap_card}>
              <div className={classes.support_section__container_levels_card}>
                <div className={classes.support_section__container_levels_card_wrap_num_title}>
                  <div className={classes.support_section__container_levels_card_num}>
                    01
                  </div>
                  <div className={classes.support_section__container_levels_card_title}>
                    линия
                  </div>
                </div>

                <div className={classes.support_section__container_levels_double_text}>
                  <div className={classes.support_section__container_levels_first_text}>
                    Осуществляет первоначальный мониторинг, корректирует задачи мониторинга в соответствии
                    с пожеланиями заказчика, формирует и корректирует схему эскалации.
                  </div>
                  <div className={classes.support_section__container_levels_second_text}>
                    Живая техподдержка в чатах
                    <br></br>
                    <br></br>
                    Мониторинг инцидентов, оповещение в соответствии со схемой эскалации
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.support_section__container_levels_wrap_card}>
              <div className={classes.support_section__container_levels_card}>
                <div className={classes.support_section__container_levels_card_wrap_num_title}>
                  <div className={classes.support_section__container_levels_card_num}>
                    02
                  </div>
                  <div className={classes.support_section__container_levels_card_title}>
                    линия
                  </div>
                </div>

                <div className={classes.support_section__container_levels_double_text}>
                  <div className={classes.support_section__container_levels_first_text}>
                    Помогает разработать и скорректировать инструкции по обработке инцидентов и
                    использует эти инструкции для устранения проблем без участия заказчика.
                  </div>
                  <div className={classes.support_section__container_levels_second_text}>
                    Решение инцидентов <br></br>
                    в соответствии с инструкциями
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.support_section__container_levels_wrap_card}>
              <div className={classes.support_section__container_levels_card}>
                <div className={classes.support_section__container_levels_card_wrap_num_title}>
                  <div className={classes.support_section__container_levels_card_num}>
                    03
                  </div>
                  <div className={classes.support_section__container_levels_card_title}>
                    линия
                  </div>
                </div>

                <div className={classes.support_section__container_levels_double_text}>
                  <div className={classes.support_section__container_levels_first_text}>
                    Самостоятельно анализирует инциденты, решает их, разрабатывает инструкции по подобным инцидентов
                    в будущем и формирует отчеты на основе проведенного анализа.
                  </div>
                  <div className={classes.support_section__container_levels_second_text}>
                    Анализ и самостоятельное решение инцидентов
                    <br></br>
                    <br></br>
                    Разработка инструкций для решения инцидентов
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <HorizontalCardBlock data={dataHorizontalCards}/>
        </div>

        <div>
          <HorizontalCardBlock data={dataHorizontalCardsSecond}/>
        </div>

        <div className={classes.support_section__not_line}>
          <div className={classes.support_section__only_line}>~</div>
          <div className={classes.support_section__second_end_big_text}>
            Если вам не хватает максимально персонального подхода — выделим для вас собственного менеджера
          </div>

          <div className={classes.support_section__container_end_small_text}>
            Кроме того, наши специалисты могут провести аудит вашей инфраструктуры, порекомендовать или
            самостоятельно внедрить необходимые для бесперебойной работы изменения, внедрить DevOps-практики, организовать
            проведение нагрузочного тестирования и обеспечить повышенную отказоустойчивость во время планируемых пиковых нагрузок.
          </div>
        </div>

        {/*  */}

        <div className={classes.support_section__line}>
          <div className={classes.support_section__container_list}>
            Поддерживаем технологии
          </div>

          <div className={classes.support_section__container_all_lists}>
            {dataListCards?.map((item, index) =>
              <React.Fragment key={index}>
                <ListCard data={item} num={`0.${index * 10}`}/>
              </React.Fragment>,
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportSection;