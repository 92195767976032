/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@mui/styles';

const useStylesCheckboxUI = makeStyles((theme) => ({
  checkbox_ui__label: {
    width: '100%',
    alignItems: 'flex-start !important',
    marginLeft: '0 !important',
    marginRight: '0 !important',
    '&:hover .hov': {
    
    },
    '&:hover .hov.ckeck': {
      border: `1px solid ${theme.palette.red}`,
      minWidth: '24px',
      maxWidth: '24px',
      height: '24px',
    },
  },

  checkbox_ui: {
    borderRadius: '5px !important',
    border: `1px solid ${theme.palette.white}`,
    minWidth: '24px',
    maxWidth: '24px',
    height: '24px',
    transitionDuration: 0,
    padding: '0 !important',
    '&.ckeck': {
      padding: '0 !important',
      border: `1px solid ${theme.palette.red}`,
      background: theme.palette.red,
      transitionDuration: 0,
      '&:hover': {
        background: theme.palette.red,
      },
    },
    '&.disabled': {
      border: `1px solid ${theme.palette.white}`,
      background: theme.palette.primary.black_20,
      transitionDuration: 0,
    },
    '&:hover': {
      background: theme.palette.primary.white,
    },
    '& > span': {
      width: 'inherit',
    },
    '&.error': {
      border: `1px solid ${theme.palette.primary.alert}`,
    },
  },

  checkbox_ui__checked: {
    display: 'block',
    borderRadius: '5px !important',
    // border: `1px solid ${theme.palette.red}`,
    // background: theme.palette.red,
    width: '16px',
    height: '12px',
  },

  checkbox_ui__no_checked: {
    padding: '0 !important',
    borderRadius: '5px !important',
    border: `1px solid ${theme.palette.white}`,
    minWidth: '24px',
    maxWidth: '24px',
    height: '24px',
    '&.error': {
      border: `1px solid ${theme.palette.red}`,
    },
  },

  checkbox_ui__group: {
    display: 'inline-block',
    [theme.breakpoints.up('bp_768')]: {
      display: 'flex',
    },
  },

  checkbox_ui__label_text: {
    marginLeft: '12px',
    '& span': {
      ...theme.typography.P2_R_900,
      color: theme.palette.black_80,
    },
    '& a': {
      ...theme.typography.P2_R_900,
      color: theme.palette.white,
    },
  },

}));

export default useStylesCheckboxUI;
