import { makeStyles } from '@mui/styles';

const useStylesMainContainer = makeStyles((theme) => ({
  main_container: {
    
  },

  main_container__wrap: {
    position: 'relative',
    zIndex: 10,
    background: theme.palette.black_100,
  },
}));

export default useStylesMainContainer;
