import { makeStyles } from '@mui/styles';

const useStylesServicesCard = makeStyles((theme) => ({
  services_card: {
    borderTop: `1px solid ${theme.palette.white}`,
    padding: '24px 0',
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
    },
    [theme.breakpoints.up('bp_1900')]: {
      padding: '32px 0',
    },
    '&:nth-child(odd) > div': {
      [theme.breakpoints.up('bp_560')]: {
        paddingRight: 24,
        borderRight: `1px solid ${theme.palette.black_80}`,
      },
      [theme.breakpoints.up('bp_1900')]: {
        paddingRight: 32,
      },
    },
    '&:nth-child(even) > div': {
      [theme.breakpoints.up('bp_560')]: {
        paddingLeft: 24,
      },
      [theme.breakpoints.up('bp_1900')]: {
        paddingLeft: 32,
      },
    },
  },

  services_card__content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 160,
    [theme.breakpoints.up('bp_560')]: {
      height: 208,
    },
    [theme.breakpoints.up('bp_1900')]: {
      height: 276,
    },
  },

  services_card__card_text: {
    ...theme.typography.P2_R_900,
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.P2_R_1900,
    },
  },

  services_card__card_wrap_btn: {
    maxWidth: 40,
    minWidth: 40,
    overflow: 'hidden',
    [theme.breakpoints.up('bp_1900')]: {
      maxWidth: 54,
      minWidth: 54,
    },
    '& button': {
      cursor: 'default',
    },
    '& button.mini:hover': {
      cursor: 'default',
      background: theme.palette.red,
    },
    '& button.mini:active': {
      cursor: 'default',
      opacity: 1,
    },
  },

  
}));

export default useStylesServicesCard;
