import React from 'react';

import ButtonUI from '../UI/ButtonUI/ButtonUI';

import useStylesServicesCard from './ServicesCardStyles';

const ServicesCard = ({
  item = {},
  num = 0,
}) => {
  const classes = useStylesServicesCard();

  return (
    <div className={classes.services_card}>
      <div className={classes.services_card__content}>
        <div className={classes.services_card__card_text}>
          {item?.title}
        </div>

        <div className={classes.services_card__card_wrap_btn}>
          <ButtonUI
            typeBtn="mini"
            text={`0${num}`}
          />
        </div>
      </div>
    </div>
  );
};

export default ServicesCard;
