import { makeStyles } from '@mui/styles';

const useStylesCustomInputMask = makeStyles((theme) => ({
  custom_input_mask__wrap: {
    position: 'relative',
  },

  custom_input_mask: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 6,
    paddingLeft: 0,
    borderBottom: `1px solid ${theme.palette.black_80}`,
    background: 'transparent',
    ...theme.typography.P2_R_900,
    color: theme.palette.white,

    '&:disabled': {
      // borderBottom: `1px solid ${theme.palette.black_100}`,
      // background: theme.palette.black_60,
    },
    '&:hover': {
      // borderBottom: `1px solid ${theme.palette.red}`,
    },
    '&:focus': {
      borderBottom: `1px solid ${theme.palette.white}`,
      'caret-color': theme.palette.red,
    },
    '&.error': {
      // borderBottom: `1px solid ${theme.palette.white}`,
      color: theme.palette.red,
    },

    '&::-webkit-input-placeholder': {
      color: `${theme.palette.black_80} !important`,
    },
    '&::-moz-placeholder': {
      color: `${theme.palette.black_80} !important`,
    },
    '&:-moz-placeholder': {
      color: `${theme.palette.black_80} !important`,
    },
    '&:-ms-input-placeholder': {
      color: `${theme.palette.black_80} !important`,
    },
  },
  
  custom_input_mask__wrap_icon: {
    position: 'absolute',
    right: 0,
    bottom: 8,
    width: 24,
    height: 24,
    '& svg': {
      width: 24,
      height: 24,
      display: 'block',
    },
  },
}));

export default useStylesCustomInputMask;
