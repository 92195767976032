export const actionTypes = {

  // common
  MODAL: 'MODAL',
  SEND_DISCUSS_PROJECT: 'SEND_DISCUSS_PROJECT',
  SEND_DISCUSS_PROJECT_ERROR: 'SEND_DISCUSS_PROJECT_ERROR',
  SEND_DISCUSS_PROJECT_SUCCESS: 'SEND_DISCUSS_PROJECT_SUCCESS',
  IS_SEND_DISCUSS_PROJECT: 'IS_SEND_DISCUSS_PROJECT',
  GET_RECAPTCHA: 'GET_RECAPTCHA',
  GET_RECAPTCHA_SUCCESS: 'GET_RECAPTCHA_SUCCESS',
};
