import React from 'react';

import ButtonUI from '../UI/ButtonUI/ButtonUI';

import useStylesCircleCard from './CircleCardStyles';

const CircleCard = ({
  data = {},
}) => {
  const classes = useStylesCircleCard();

  return (
    <div className={classes.circle_card}>
      <div className={classes.circle_card__circle}>
        <div
          className={classes.circle_card__title}
          dangerouslySetInnerHTML={{ __html: data?.title }}
        />

        <div className={classes.circle_card__wrap_btn}>
          <ButtonUI
            typeBtn="mini"
            text={data?.letter}
          />
        </div>
      </div>
    </div>
  );
};

export default CircleCard;
