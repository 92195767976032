import { makeStyles } from '@mui/styles';

const useStylesHorizontalCard = makeStyles((theme) => ({
  horizontal_card: {
    // width: 256,
    height: 264,
    borderRadius: 32,
    background: theme.palette.black_95,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('bp_560')]: {
      // width: 312,
      height: 288,
      padding: 32,
    },
    [theme.breakpoints.up('bp_900')]: {
    },
    [theme.breakpoints.up('bp_1440')]: {
      width: 288,
    },
    [theme.breakpoints.up('bp_1900')]: {
      width: 391,
      height: 391,
      padding: 43,
    },
  },

  horizontal_card_top: {
    
  },

  horizontal_card_title: {
    ...theme.typography.h4,
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h4_1900,
    },
  },

  horizontal_card_wrap_btn: {
    width: 40,
    height: 40,
    [theme.breakpoints.up('bp_1900')]: {
      width: 54,
      height: 54,
    },
    '& button': {
      cursor: 'default',
    },
    '& button.mini:hover': {
      cursor: 'default',
      background: theme.palette.red,
    },
    '& button.mini:active': {
      cursor: 'default',
      opacity: 1,
    },
  },

}));

export default useStylesHorizontalCard;
