import React from 'react';

import TeamCard from '../TeamCard/TeamCard';

import useStylesTeamCardBlock from './TeamCardBlockStyles';

const TeamCardBlock = ({
  data = {},
}) => {
  const classes = useStylesTeamCardBlock();

  return (
    <div className={classes.team_card_block}>
      <div className={classes.team_card_block__container}>
        <div
          className={classes.team_card_block__left_part}
          dangerouslySetInnerHTML={{ __html: data?.title }}
        />

        <div className={classes.team_card_block__right_part}>
          {data?.items?.map((item, index) =>
            <React.Fragment key={index}>
              <TeamCard data={item}/>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamCardBlock;
