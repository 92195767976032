import { makeStyles } from '@mui/styles';

const useStylesBigCardBlock = makeStyles((theme) => ({
  big_card_block: {
    borderTop: `1px solid ${theme.palette.white}`,
    paddingTop: 24,
    paddingBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      paddingBottom: 128,
    },
    [theme.breakpoints.up('bp_1900')]: {
      paddingTop: 32,
      paddingBottom: 170,
    },
  },

  big_card_block__container: {

  },

  big_card_block__left_part: {
    ...theme.typography.h4,
    color: theme.palette.red,
    marginBottom: 48,
    
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h4_1900,
    },
  },

  big_card_block__right_part: {
    position: 'relative',

  },

  big_card_block__wrap_cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridRowGap: 24,
    [theme.breakpoints.up('bp_768')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridRowGap: 48,
      gridColumnGap: 48,
    },
    [theme.breakpoints.up('bp_1440')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },

  big_card_block__wrap_card: {
  },

  

}));

export default useStylesBigCardBlock;
