import { makeStyles } from '@mui/styles';

const useStylesRentalRequestForm = makeStyles((theme) => ({
  container_form_element: {
    width: '100%',
    marginBottom: 24,
    position: 'relative',
  },

  container_form_element__wrap_label: {
    display: 'flex',
    alignItems: 'center',
  },

  container_form_element__label: {
    ...theme.typography.P4_M_900,
    color: theme.palette.white,
  },

  container_form_element__red_star: {
    marginLeft: 3,
    width: 16,
    height: 16,
    display: 'block',
  },

  container_form_element__dop_text: {
    ...theme.typography.P4_M_900,
    color: theme.palette.red,
    marginTop: 2,
    position: 'absolute',
  },

}));

export default useStylesRentalRequestForm;
