import { makeStyles } from '@mui/styles';

const useStylesBigCard = makeStyles((theme) => ({
  big_card: {
    height: 440,
    borderRadius: 32,
    background: theme.palette.black_95,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('bp_560')]: {
      borderRadius: 40,
      height: 480,
      padding: 32,
    },
    [theme.breakpoints.up('bp_900')]: {
      borderRadius: 40,
      height: 490,
      padding: 56,
    },
    [theme.breakpoints.up('bp_1440')]: {
      height: 488,
    },
    [theme.breakpoints.up('bp_1900')]: {
      height: 500,
      padding: 75,
    },
  },

  big_card__card_top: {
    
  },

  big_card__card_title: {
    ...theme.typography.h2,
    marginBottom: 24,
    [theme.breakpoints.up('bp_900')]: {
      ...theme.typography.h3_tab,
    },
    [theme.breakpoints.up('bp_1280')]: {
      ...theme.typography.h3_900,
    },
    [theme.breakpoints.up('bp_1900')]: {
      marginBottom: 32,
    },
  },

  big_card__card_description: {
    ...theme.typography.P2_R_900,
  },

  big_card__card_wrap_btn: {
    '& button': {
      width: 'auto !important',
      padding: '0 16px',
      cursor: 'default',
    },
    '& button.mini:hover': {
      cursor: 'default',
      background: theme.palette.red,
    },
    '& button.mini:active': {
      cursor: 'default',
      opacity: 1,
    },
  },

  
}));

export default useStylesBigCard;
