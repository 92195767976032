import { makeStyles } from '@mui/styles';

const useStylesCircleCardBlock = makeStyles((theme) => ({
  circle_card_block: {
    borderTop: `1px solid ${theme.palette.white}`,
    paddingTop: 24,
    paddingBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      paddingBottom: 128,
    },
    [theme.breakpoints.up('bp_1900')]: {
      paddingTop: 32,
      paddingBottom: 170,
    },
  },

  circle_card_block__container: {
    [theme.breakpoints.up('bp_900')]: {
      display: 'flex',
    },
  },

  circle_card_block__left_part: {
    ...theme.typography.h4,
    color: theme.palette.red,
    marginBottom: 48,
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h4_1900,
    },
  },

  circle_card_block__right_part: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('bp_560')]: {
      width: 448,
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: '0 auto',
    },
    [theme.breakpoints.up('bp_768')]: {
      width: 672,
      margin: '0 auto',
    },
    [theme.breakpoints.up('bp_900')]: {
      width: 448,
      marginLeft: 'auto',
      marginRight: '0',
    },
    [theme.breakpoints.up('bp_1280')]: {
      width: 672,
    },
    [theme.breakpoints.up('bp_1900')]: {
      width: 896,
    },
  },

}));

export default useStylesCircleCardBlock;
