
import React from 'react';

import useStylesSection from './SectionStyles';

const Section = ({
  children,
  customClass = '',
  id = '',
}) => {
  const classes = useStylesSection();

  return (
    <div className={`${classes.section} ${customClass}`} id={id}>
      {children}
    </div>
  );
};

export default Section;
