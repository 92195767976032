import { makeStyles } from '@mui/styles';

const useStylesModalUI = makeStyles((theme) => ({
  modal_ui: {},

  modal_ui__overlay: {
  },

  modal_ui__wrap_content: {
    outline: 'none',
    overflow: 'auto',
    cursor: 'default',
  },

  modal_ui__content: {
    background: theme.palette.black_100,
  },

  modal_ui__wrap_elem: {

  },

  '@global': {
    '.ReactModal__Overlay': {
      position: 'fixed',
      top: '-100vh',
      left: 0,
      width: '100%',
      minHeight: '100%',
      height: '100%',

      transition: 'all 500ms ease-in-out',
      background: 'rgba(0, 0, 0, 0.5) !important',
      zIndex: 1000,
      overflow: 'auto',
    },

    '.ReactModal__Overlay--after-open': {
      cursor: 'pointer',
      top: 0,
      '&.menu': { 
        top: 57,
        paddingBottom: 57,
        [theme.breakpoints.up('bp_560')]: {
          top: 73,
          paddingBottom: 73,
        },
      },
    },

    '.ReactModal__Overlay--before-close': {
      top: '-100vh',
      '&.menu': { 
        top: '-100vh',
      },
    },
  },
}));

export default useStylesModalUI;
