import { makeStyles } from '@mui/styles';

const useStylesModalMenu = makeStyles((theme) => ({
  modal_menu: {
    minHeight: '100vh',
    [theme.breakpoints.up('bp_1280')]: {
      minHeight: 'calc(100vh - 225px)',
    },
  },

  modal_menu__all_cards: {
    marginBottom: 48,
    [theme.breakpoints.up('bp_900')]: {
      display: 'flex',
      borderBottom: `1px solid ${theme.palette.black_80}`,
      padding: '24px 0',
    },
  },

  modal_menu__card: {
    width: '100%',
    padding: '24px 0',
    transition: 'all 300ms ease-in-out',
    borderBottom: `1px solid ${theme.palette.black_80}`,
    cursor: 'pointer',
    overflow: 'hidden',
    [theme.breakpoints.up('bp_560')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('bp_900')]: {
      borderBottom: 'none',
      width: '33.3%',
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    '&:nth-child(1)': {
      [theme.breakpoints.up('bp_900')]: {
        paddingRight: 24,
      },
    },
    '&:nth-child(2)': {
      [theme.breakpoints.up('bp_900')]: {
        borderLeft: `1px solid ${theme.palette.black_80}`,
        borderRight: `1px solid ${theme.palette.black_80}`,
        padding: '0 24px',
      },
    },
    '&:nth-child(3)': {
      [theme.breakpoints.up('bp_900')]: {
        paddingLeft: 24,
      },
    },

    '&:hover .wrap_num': {
      transition: 'all 300ms ease-in-out',
      color: theme.palette.white,
    },
    '&:hover .title_card': {
      transition: 'all 300ms ease-in-out',
      color: theme.palette.red,
    },
    '&:hover .description_card': {
      transition: 'all 300ms ease-in-out',
      color: theme.palette.white,
    },
    '&:hover .arrow': {
      transition: 'all 300ms ease-in-out',
      opacity: '1 !important',
    },
  },

  modal_menu__wrap_num: {
    ...theme.typography.h1,
    color: theme.palette.black_80,
    marginBottom: 24,
    position: 'relative',
    fontVariantNumeric: 'oldstyle-nums',
    transition: 'all 300ms ease-in-out',
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h1_560,
      width: '50%',
    },
    [theme.breakpoints.up('bp_900')]: {
      width: '100%',
      ...theme.typography.h1_900,
      paddingBottom: 64,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h1_1900,
    },
  },

  header__wrap_arrow_right: {
    transition: 'all 300ms ease-in-out',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    width: 24,
    height: 24,
    [theme.breakpoints.up('bp_560')]: {
      opacity: 0,
    },
    '& svg': {
      width: 24,
      height: 24,
      display: 'block',
    },
  },

  modal_menu__wrap_double_text: {
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
    },
    [theme.breakpoints.up('bp_900')]: {
      width: '100%',
    },
  },

  modal_menu__title_card: {
    ...theme.typography.h3,
    transition: 'all 300ms ease-in-out',
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h3_900,
      marginBottom: 24,
    },
    [theme.breakpoints.up('bp_900')]: {

    },
  },

  modal_menu__description_card: {
    display: 'none',
    transition: 'all 300ms ease-in-out',
    ...theme.typography.P2_R_900,
    [theme.breakpoints.up('bp_560')]: {
      display: 'block',
      color: theme.palette.black_60,
    },
  },

  modal_menu__footer: {
    paddingBottom: 24,
    [theme.breakpoints.up('bp_900')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },

  modal_menu__footer_left_part: {
    marginBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('bp_900')]: {
      width: '55%',
      marginBottom: 0,
    },
  },

  modal_menu__footer_right_part: {
    [theme.breakpoints.up('bp_900')]: {
      width: '37%',
    },
  },

  modal_menu__footer_left_part_cell: {
    width: '100%',
    marginBottom: 24,
    '&:first-child': {
      paddingRight: 20,
    },
    [theme.breakpoints.up('bp_560')]: {
      width: '50%',
      marginBottom: 0,
    },
  },

  modal_menu__footer_left_part_cell_title: {
    ...theme.typography.P2_M_900,
    marginBottom: 16,
  },

  modal_menu__footer_left_part_cell_description: {
    ...theme.typography.P2_R_900,
    color: theme.palette.black_60,
    '& a': {
      ...theme.typography.P2_R_900,
      color: theme.palette.black_60,
    },
  },

  modal_menu__footer_wrap_last_words: {
    display: 'flex',
    flexFlow: 'column',
    [theme.breakpoints.up('bp_560')]: {
      flexFlow: 'row',
    },
  },

  modal_menu__footer_last_word: {
    color: theme.palette.white,
    [theme.breakpoints.up('bp_560')]: {
      marginRight: 32,
    },
    '&:first-child': {
      marginBottom: 16,
      [theme.breakpoints.up('bp_560')]: {
        marginBottom: 0,
      },
    },
  },

}));

export default useStylesModalMenu;
