import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';

import { useForm } from 'react-hook-form'; // для формы
import { yupResolver } from '@hookform/resolvers/yup'; // для формы
import * as yup from 'yup'; // для формы

import IconUI from '../../../UI/IconUI/IconUI';
import ContainerFormElement from '../../../UI/ContainerFormElement/ContainerFormElement';
import ButtonUI from '../../../UI/ButtonUI/ButtonUI';
import InputUI from '../../../UI/InputUI/InputUI';
import CustomInputMask from '../../../UI/CustomInputMask/CustomInputMask';
import TextAreaUI from '../../../UI/TextAreaUI/TextAreaUI';
import InputFileUI from '../../../UI/InputFileUI/InputFileUI';
import CheckboxUI from '../../../UI/CheckboxUI/CheckboxUI';

import { isSendSelector, recaptchaSiteKey, errorMessage } from '../../../../store/selectors/commonSelectors';
import {sendDiscussProjectFormAction, isSendDiscussProjectAction} from '../../../../store/actions/commonActions';

import useStylesDiscussProject from './DiscussProjectStyles';


const DiscussProject = ({
  handlerCloceModal = () => {},
  sendDiscussProjectFormAction = () => {},
  isSendDiscussProjectAction = () => {},
  isSend = false,
  recaptchaSiteKey,
  errorMessage,
}) => {
  const classes = useStylesDiscussProject();
  const [activeTab, setActiveTab] = useState(1);

  const tabs = [
    { name: 'Москва', id: 1 },
    { name: 'Калининград', id: 2 },
  ];

  const contacts = [
    { 
      tab: 1,
      content: [
        { label: 'Телефон', text: '+7 (495) 532 38 80', href: 'tel:+74955323880' },
        { label: 'Адрес', text: 'Варшавское шоссе, д. 1, стр. 6, БЦ W Plaza 2', href: '' },
        { label: 'Общие вопросы', text: 'info@buroburo.ru', href: 'mailto:info@buroburo.ru' },
        { label: 'PR и мероприятия', text: 'pr@buroburo.ru', href: 'mailto:pr@buroburo.ru' },
      ],
    },
    { 
      tab: 2,
      content: [
        { label: 'Телефон', text: '8 (800) 550 12 38', href: 'tel:88005501238' },
        { label: 'Адрес', text: 'ул. Верхнеозёрная, д. 33А', href: '' },
        { label: 'Общие вопросы', text: 'info@buroburo.ru', href: 'mailto:info@buroburo.ru' },
        { label: 'PR и мероприятия', text: 'pr@buroburo.ru', href: 'mailto:pr@buroburo.ru' },
      ],
    },
  ];



  useEffect(() => {
    isSendDiscussProjectAction(false);
  }, [isSendDiscussProjectAction]);

  // доступные типы фалов для загрузки
  const typesFile = (i) => {
    return (
      i?.type !== 'image/png' 
      && i?.type !== 'image/svg'
      && i?.type !== 'image/jpg'
      && i?.type !== 'image/jpeg'
      && i?.type !== 'image/tiff'
      && i?.type !== 'application/pdf'
      && i?.type !== 'text/plain'
      && i?.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      && i?.type !== 'application/msword'
      && i?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      && i?.type !== 'application/vnd.ms-excel'
    );
  };

  const schema = yup.object().shape({
    inputName: yup.string()
      .required('Обязательное поле')
      .min(3, 'Минимум 3 буквы')
      .matches(/^[а-яА-ЯёЁa-zA-Z\s]+$/, 'Разрешены только буквы')
      .default(''),
    inputEmail: yup.string()
      .required('Обязательное поле')
      .email('Email не верного формата')
      .default(''),
    // inputCapcha: yup.string()
    //   .required('Обязательное поле')
    //   .test('wrongText', 'Неверно введен текст с картинки', (value) => { // создаем свое правило валидации
    //     return (value?.length > 0) && (textCapcha?.length > 0) && (value === textCapcha);
    //   })
    //   .default(''),
    checkboxIGree: yup.bool().oneOf([true]).default(true),
    inputPhone: yup.number()
      .typeError('Недостаточно цифр')
      .required('Обязательное поле'),
    // textAreaField: yup.string(),
    inputFile: yup.mixed()
      .test('fileSizeZero', 'Файл, который вы грузите, пустой. Проверьте файл и повторите попытку', (value) => {
        return value?.length > 0 ? (value[0]?.size !== 0) : true;
      })
      .test('fileSize', 'Слишком большой файл. Размер файла не должен превышать 20 мб', (value) => {
        return value?.length > 0 ? (value[0]?.size <= 20000000) : true;
      })
      .test('typeFile', 'Неверный формат файла', (value) => {
        return value?.length > 0 ? (!typesFile(value[0])) : true;
      }),
  });

  const {
    watch, // аналог state как в useState()
    // setValue, // аналог setState как в useState()
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast(),
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  // позволяет отслеживать значение элементов в реальном времени (аналог state в useState())
  const valueInputFile = watch('inputFile');
  const valueCheckboxIGree = watch('checkboxIGree');

  // отправка формы
  const submitForm = async (data) => {

    window.grecaptcha
      .execute(recaptchaSiteKey)
      .then(token => {
        const obj = {
          inputEmail: data?.inputEmail,
          // inputFile: data?.inputFile,
          inputFile: data?.inputFile[0],
          inputName: data?.inputName,
          inputPhone: data?.inputPhone ? String(data?.inputPhone).replace(/\D/gim, '') : '',
          textAreaField: data?.textAreaField,
          captcha: token,
        };

        sendDiscussProjectFormAction(obj);
      });
  };

  // очищаем форму после успешной отправки
  // useEffect(() => {
  //   if (isSend) {
  //     setValue('inputName', '');
  //     setValue('inputEmail', '');
  //     setValue('inputPhone', '');
  //     setValue('datePicker', null);
  //     setValue('textAreaField', '');
  //     setValue('selectConsultation', { value: '', label: '' });
  //     setValue('selectTime', { value: '', label: '' });
  //   }
  // }, [isSend, setValue]);
  

  return (
    <div className={classes.discuss_project}>
      <div className={classes.discuss_project__header}>
        <div className={classes.discuss_project__header_word}>обсудить проект</div>

        <div
          className={classes.discuss_project__wrap_icon_close}
          onClick={() => handlerCloceModal('', false)}
        >
          <IconUI name="icon_close"/>
        </div>
      </div>

      <div className={classes.discuss_project__container}>
        <div className={classes.discuss_project__content}>
          <div className={classes.discuss_project__left_part}>
            <div>
              <div className={classes.discuss_project__title}>Контакты</div>

              <div className={classes.discuss_project__wrap_tabs}>
                {tabs.map((i, ind) =>
                  <div
                    className={`${classes.discuss_project__tab} ${i.id === activeTab ? 'active' : ''}`}
                    key={ind}
                    onClick={() => setActiveTab(i.id)}
                  >
                    {i.name}
                  </div>
                )}
              </div>

              <div className={classes.discuss_project__wrap_content_tabs}>
                {contacts.map((i, ind) => 
                  <React.Fragment key={ind}>
                    {i.content.map((item, index) => {
                      if (i.tab === activeTab) {
                        return (
                          <div className={classes.discuss_project__content_cell} key={index}>
                            <div className={classes.discuss_project__content_label}>
                              {item.label}
                            </div>

                            {item.href
                              && (
                                <a href={item.href} className={classes.discuss_project__content_text}>
                                  {item.text}
                                </a>
                              )}

                            {!item.href
                              && (
                                <div className={classes.discuss_project__content_text}>
                                  {item.text}
                                </div>
                              )}
                            
                          </div>
                        );
                      };
                      
                      return null;
                    })}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          <div className={classes.discuss_project__wrap_right_part}>
            <div className={classes.discuss_project__right_part}>
              <div className={classes.discuss_project__right_part_content}>
                <div className={classes.discuss_project__title}>Заявка на обслуживание</div>
              
                {!isSend
                  && (
                    <form onSubmit={handleSubmit(submitForm)}>
                      <ContainerFormElement
                        textLabel="Ваше имя"
                        necessarily
                      >
                        <InputUI
                          error={errors.inputName}
                          validation={{
                            ...register('inputName'),
                          }}
                        />
                      </ContainerFormElement>

                      <div className={classes.discuss_project__wrap_double_elem}>
                        <div>
                          <ContainerFormElement
                            textLabel="Адрес эл. почты"
                            necessarily
                          >
                            <InputUI
                              type="email"
                              error={errors.inputEmail}
                              validation={{
                                ...register('inputEmail'),
                              }}
                            />
                          </ContainerFormElement>
                        </div>
                        <div>
                          <ContainerFormElement
                            textLabel="Телефон"
                            necessarily
                          >
                            <CustomInputMask
                              type="tel"
                              control={control}
                              error={errors.inputPhone}
                              name="inputPhone"
                            />
                          </ContainerFormElement>
                        </div>
                      </div>

                      <ContainerFormElement textLabel="Сообщение">
                        <TextAreaUI
                          placeholder="Введите текст"
                          max={255}
                          validation={{
                            ...register('textAreaField'),
                          }}
                        />
                      </ContainerFormElement>

                      <div className={classes.discuss_project__wrap_elem}>
                        <ContainerFormElement
                          textLabel="Вложение"
                          textBottom={errors?.inputFile?.message}
                        >
                          <InputFileUI
                            placeholder="Выберите файл"
                            error={errors.inputFile}
                            valueInputFile={valueInputFile}
                            validation={{
                              ...register('inputFile'),
                            }}
                          />
                        </ContainerFormElement>
                      </div>
                      
                      
                      {/*<div className={classes.discuss_project__container_wrap_capcha}>*/}
                      {/*  <div className={classes.discuss_project__wrap_capcha}>*/}
                      {/*    <Capcha setTextCapcha={setTextCapcha} />*/}
                      {/*  </div>*/}

                      {/*  <ContainerFormElement*/}
                      {/*    textLabel="Введите код с картинки"*/}
                      {/*    necessarily*/}
                      {/*    textBottom={errors?.inputCapcha?.type === 'wrongText' && errors?.inputCapcha?.message}*/}
                      {/*  >*/}
                      {/*    <InputUI*/}
                      {/*      error={errors.inputCapcha}*/}
                      {/*      validation={{*/}
                      {/*        ...register('inputCapcha'),*/}
                      {/*      }}*/}
                      {/*    />*/}
                      {/*  </ContainerFormElement>*/}
                      {/*</div>*/}
                      

                      <div className={classes.discuss_project__wrap_checkbox}>
                        <CheckboxUI
                          textLabel="<span>Настоящим подтверждаю, что я  соглашаюсь</span> <a href='https://buroburo.ru/policy/'>с политикой обработки персональных данных.</a>"
                          error={errors.checkboxIGree}
                          checked={valueCheckboxIGree}
                          validation={{
                            ...register('checkboxIGree'),
                          }}
                          form
                        />
                      </div>

                      {
                        (errorMessage.length > 0 && errorMessage.find((el) => el.field === 'captcha')) &&
                        <div className={classes.discuss_project__container_wrap_capcha__error}>
                          {errorMessage.find((el) => el.field === 'captcha').message}
                        </div>
                      }

                      <div className={classes.discuss_project__wrap_btn}>
                        <ButtonUI
                          typeBtn='big-narrow'
                          type="submit"
                          text="Отправить"
                          disabled={!valueCheckboxIGree}
                        />
                      </div>
                    </form>
                  )}
                
                {isSend
                  && (
                    <div className={classes.discuss_project__success}>
                      <h2 className={classes.discuss_project__success_title}>
                        сообщение отправлено
                      </h2>
                      <div className={classes.discuss_project__success_description}>
                        наш сотрудник свяжется с вами в ближайшее время
                      </div>
                    
                      <div className={classes.discuss_project__wrap_icon_logo}>
                        <IconUI name="icon_logo"/>
                      </div>
                    </div>
                  )}
              </div>

              <div className={classes.discuss_project__wrap_text_capcha}>
                Этот сайт защищен reCAPTCHA, применяются Политика конфиденциальности и Условия обслуживания Google.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isSend: isSendSelector(state),
  recaptchaSiteKey: recaptchaSiteKey(state),
  errorMessage: errorMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
  sendDiscussProjectFormAction: (data) => dispatch(sendDiscussProjectFormAction(data)),
  isSendDiscussProjectAction: (bool) => dispatch(isSendDiscussProjectAction(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscussProject);
