import { makeStyles } from '@mui/styles';

const useStylesNums = makeStyles((theme) => ({
  nums: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  nums__first: {
    ...theme.typography.h1,
    fontVariantNumeric: 'oldstyle-nums',
    color: theme.palette.red,
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h1_560,
    },
    [theme.breakpoints.up('bp_900')]: {
      ...theme.typography.h1_900,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h1_1900,
    },
  },

  nums__line: {
    ...theme.typography.h1,
    color: theme.palette.black_80,
    lineHeight: '40px',
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h1_560,
      lineHeight: '80px',
    },
    [theme.breakpoints.up('bp_900')]: {
      ...theme.typography.h1_900,
      lineHeight: '119px',
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h1_1900,
      lineHeight: '160px',
    },
  },

  nums__second: {
    ...theme.typography.h1,
    fontVariantNumeric: 'oldstyle-nums',
    color: theme.palette.black_80,
    [theme.breakpoints.up('bp_560')]: {
      ...theme.typography.h1_560,
    },
    [theme.breakpoints.up('bp_900')]: {
      ...theme.typography.h1_900,
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h1_1900,
    },
  },
  
}));

export default useStylesNums;
