/* eslint-disable max-len */
import React from 'react';

import IconUI from '../IconUI/IconUI';

import useStylesInputUI from './InputUIStyles';

const InputUI = function ({
  type = 'text',
  className = '',
  placeholder = '',
  disabled = false,
  inputMode = '',
  validation = {},
  error = false,
  max = 100,
  onlyNum = false,
  setValue = () => { },
}) {
  const classes = useStylesInputUI();

  return (
    <div className={classes.input_ui}>
      <input
        type={type}
        className={`${classes.input_ui__input} ${error ? 'error' : ''} ${className}`}
        placeholder={placeholder}
        disabled={disabled}
        inputMode={inputMode}
        maxLength={max}
        {...validation}
        onBlur={(e) => {
          // создает возможность использовать только цифры
          if (onlyNum) {
            const value = +e.target.value.replace(/\D/g, '') || '';
            validation.onChange(e);
            setValue(validation.name, value);
          }
          validation.onChange(e);
          setValue(validation.name, e.target.value);
        }}
        onChange={(e) => { // используется вместе с onBlur, что бы при редактировании минималдьного числа не появлялся NaN
          if (onlyNum) {
            const value = +e.target.value.replace(/\D/g, '') || '';
            validation.onChange(e);
            setValue(validation.name, value);
          }
          validation.onChange(e);
          setValue(validation.name, e.target.value);
        }}
      />

      {error
        && (
          <div className={classes.input_ui__wrap_icon}>
            <IconUI name="icon_alert"/>
          </div>
        )}
    </div>
    
  );
};

export default InputUI;
