import { makeStyles } from '@mui/styles';

const useStylesInputFileUI = makeStyles((theme) => ({
  input_file_ui: {
   
  },

  input_file__label: {
    display: 'inline-flex',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 6,
    paddingLeft: 0,
    paddingRight: 40,
    borderBottom: `1px solid ${theme.palette.black_80}`,
    background: 'transparent',
    ...theme.typography.P2_R_900,
    color: theme.palette.white,
    cursor: 'pointer',
    overflow: 'hidden',

    '& input': {
      display: 'none',
    },

    '&:disabled': {
      // borderBottom: `1px solid ${theme.palette.black_100}`,
      // background: theme.palette.black_60,
    },
    '&:hover': {
      // borderBottom: `1px solid ${theme.palette.red}`,
    },
    '&:focus': {
      // borderBottom: `1px solid ${theme.palette.black_100}`,
      'caret-color': theme.palette.red,
    },
    '&.error': {
      borderBottom: `1px solid ${theme.palette.red}`,
      color: theme.palette.red,
    },
    '&.full': {
      borderBottom: `1px solid ${theme.palette.white}`,
    },
    '& .placeholder': {
      color: theme.palette.black_80,
    },

    '&::-webkit-input-placeholder': {
      color: `${theme.palette.black_80} !important`,
    },
    '&::-moz-placeholder': {
      color: `${theme.palette.black_80} !important`,
    },
    '&:-moz-placeholder': {
      color: `${theme.palette.black_80} !important`,
    },
    '&:-ms-input-placeholder': {
      color: `${theme.palette.black_80} !important`,
    },
  },

  input_file_ui__wrap_icon: {
    position: 'absolute',
    right: 0,
    bottom: 8,
    height: 24,
    background: theme.palette.black_100,
    paddingLeft: 20,
    '& svg': {
      width: 24,
      height: 24,
      display: 'block',
    },
  },

}));

export default useStylesInputFileUI;
