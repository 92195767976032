import { makeStyles } from '@mui/styles';

const useStylesAnimatedElem = makeStyles((theme) => ({
  '@global': {
    // появление снизу вверх
    '.fade_in_up_small': {
      animation: ' fadeInUpSmall 0.7s linear forwards',
    },

    '@-webkit-keyframes fadeInUpSmall': {
      ' from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(0, 30px, 0)',
        transform: 'translate3d(0, 30px, 0)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
      },
    },

    '@keyframes fadeInUpSmall': {
      'from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(0, 30px, 0)',
        transform: 'translate3d(0, 30px, 0)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
      },
    },
    // появление снизу вверх

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // уменьшение слева свурху
    '.scale_left_top_small': {
      animation: ' scaleLeftTopSmall 1s linear forwards',
    },

    '@-webkit-keyframes scaleLeftTopSmall': {
      'from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(-100px, -100px, 0) scale(0.8)',
        transform: 'translate3d(-100px, -100px, 0) scale(0.8)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0) scale(1)',
        transform: 'translate3d(0, 0, 0) scale(1)',
      },
    },

    '@keyframes scaleLeftTopSmall': {
      'from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(-100px, -100px, 0) scale(0.8)',
        transform: 'translate3d(-100px, -100px, 0) scale(0.8)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0) scale(1)',
        transform: 'translate3d(0, 0, 0) scale(1)',
      },
    },
    // уменьшение слева свурху

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // уменьшение слева 
    '.scale_left_small': {
      animation: ' scaleLeftSmall 1s linear forwards',
    },

    '@-webkit-keyframes scaleLeftSmall': {
      'from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(-50px, 0, 0) scale(0.8)',
        transform: 'translate3d(-50px, 0, 0) scale(0.8)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0) scale(1)',
        transform: 'translate3d(0, 0, 0) scale(1)',
      },
    },

    '@keyframes scaleLeftSmall': {
      'from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(-50px, 0, 0) scale(0.8)',
        transform: 'translate3d(-50px, 0, 0) scale(0.8)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0) scale(1)',
        transform: 'translate3d(0, 0, 0) scale(1)',
      },
    },
    // уменьшение слева

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // появление сверху вниз
    '.fade_in_down_small': {
      animation: ' fadeInDownSmall 0.7s linear forwards',
    },

    '@-webkit-keyframes fadeInDownSmall': {
      ' from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(0, -30px, 0)',
        transform: 'translate3d(0, -30px, 0)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
      },
    },

    '@keyframes fadeInDownSmall': {
      'from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(0, -30px, 0)',
        transform: 'translate3d(0, -30px, 0)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
      },
    },
    // появление сверху вниз

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // появление снизу вверх
    '.fade_in_up_small_slow': {
      animation: ' fadeInUpSmallSlow 1.2s linear forwards',
    },

    '@-webkit-keyframes fadeInUpSmallSlow': {
      ' from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(0, 130px, 0)',
        transform: 'translate3d(0, 130px, 0)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
      },
    },

    '@keyframes fadeInUpSmallSlow': {
      'from': {
        opacity: 0,
        '-webkit-transform': 'translate3d(0, 130px, 0)',
        transform: 'translate3d(0, 130px, 0)',
      },
      'to': {
        opacity: 1,
        '-webkit-transform': 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
      },
    },


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // появление снизу вверх
    '.fade_in_up_small_full': {
      animation: ' fadeInUpSmallFull 0.75s linear forwards',
    },

    '@-webkit-keyframes fadeInUpSmallFull': {
      ' from': {
        '-webkit-transform': 'translate3d(0, 100%, 0)',
        transform: 'translate3d(0, 100%, 0)',
      },
      'to': {
        '-webkit-transform': 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
      },
    },

    '@keyframes fadeInUpSmallFull': {
      'from': {
        '-webkit-transform': 'translate3d(0, 100%, 0)',
        transform: 'translate3d(0, 100%, 0)',
      },
      'to': {
        '-webkit-transform': 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
      },
    },







    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // задержка перед анимацией
    '.animation_delay_small_400ms': {
      animationDelay: '400ms',
    },
    '.animation_delay_small_1000ms': {
      animationDelay: '1000ms',
    },
    '.animation_delay_small_2000ms': {
      animationDelay: '2000ms',
    },
    '.animation_delay_small_2400ms': {
      animationDelay: '2400ms',
    },

    
  },

}));

export default useStylesAnimatedElem;
