import { all, takeLatest } from 'redux-saga/effects';

import { actionTypes } from './types';

import {getRecaptchaSaga, sendDiscussProjectFormSaga} from './actions/commonActions';


export function* rootSaga() {
  yield all([
    takeLatest(actionTypes.SEND_DISCUSS_PROJECT, sendDiscussProjectFormSaga),
    takeLatest(actionTypes.GET_RECAPTCHA, getRecaptchaSaga),
  ]);
}

export default rootSaga;
