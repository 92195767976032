import React from 'react';
import { Helmet } from 'react-helmet';

import { dataThirdSectionBigCards } from '../../helpers/data-helper';
import BigCardBlock from '../BigCardBlock/BigCardBlock';
import Headers from '../Headers/Headers';

import useStylesBitrixSection from './BitrixSectionStyles';

const BitrixSection = () => {
  const classes = useStylesBitrixSection();

  return (
    <>
      <Helmet>
        <title>Техподдержка сайтов на Битрикс и Битрикс 24 - БюроБюро</title>
        <meta name="description" content="БюроБюро занимается техподдержкой сайтов на Битрикс и Битрикс 24, а так же обеспечивает их производительность и отказоустойчивость." />
        <meta name="keywords" content="техподдержка Битрикс, поддержка сайтов битрикс" />
      </Helmet>
      <div className={classes.bitrix_section}>
        <div className={classes.bitrix_section__not_line}>
          <Headers
            section="third"
            nums={['03', '03']}
            words={['техподдержка', 'Битрикс']}
          />

          <div className={classes.bitrix_section__wrap_text}>
            обеспечим производительность и&nbsp;отказоустойчивость проектов
            на&nbsp;<span className={classes.bitrix_section__wrap_nobp}>1С-Битрикс</span> и&nbsp;<span className={classes.bitrix_section__wrap_nobp}>1С-Битрикс</span>&nbsp;24
          </div>
        </div>

        {/*  */}

        <div>
          <BigCardBlock data={dataThirdSectionBigCards} />
        </div>

        <div className={classes.bitrix_section__only_line}>~</div>

        <div className={classes.bitrix_section__second_end_big_text}>
          Мы обеспечиваем профессиональную поддержку проектов Битрикс и занимаемся разработкой и правкой кода в них
        </div>

      </div>
    </>
  );
};

export default BitrixSection;
