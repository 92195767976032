import React from 'react';

import ButtonUI from '../UI/ButtonUI/ButtonUI';

import useStylesBigCard from './BigCardStyles';

const BigCard = ({
  data = [],
  num = 0,
}) => {
  const classes = useStylesBigCard();

  return (
    <div className={classes.big_card}>
      <div className={classes.big_card__card_top}>
        <div className={classes.big_card__card_title}>
          {data?.title}
        </div>

        <div className={classes.big_card__card_description}>
          {data?.description}
        </div>
      </div>

      <div className={classes.big_card__card_wrap_btn}>
        <ButtonUI
          typeBtn="mini"
          text={data?.textBtn}
        />
      </div>
    </div>
  );
};

export default BigCard;
