import { makeStyles } from '@mui/styles';

const useStylesTeamCardBlock = makeStyles((theme) => ({
  team_card_block: {
    borderTop: `1px solid ${theme.palette.white}`,
    paddingTop: 24,
    paddingBottom: 48,
    [theme.breakpoints.up('bp_560')]: {
      paddingBottom: 128,
    },
    [theme.breakpoints.up('bp_1900')]: {
      paddingTop: 32,
      paddingBottom: 170,
    },
  },

  team_card_block__container: {
    [theme.breakpoints.up('bp_1280')]: {
      display: 'flex',
    },
  },

  team_card_block__left_part: {
    ...theme.typography.h4,
    color: theme.palette.red,
    marginBottom: 48,
    [theme.breakpoints.up('bp_1280')]: {
      width: '50%',
    },
    [theme.breakpoints.up('bp_1900')]: {
      ...theme.typography.h4_1900,
    },
  },

  team_card_block__right_part: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('bp_560')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    [theme.breakpoints.up('bp_1280')]: {
      width: '50%',
      paddingLeft: 24,
    },
  },

}));

export default useStylesTeamCardBlock;
