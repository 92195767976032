/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import IconUI from '../IconUI/IconUI';

import useStylesCheckboxUI from './CheckboxUIStyles';

const CheckboxUI = function ({
  textLabel = '',
  classLabel = '',
  classFormControlLabel = '',
  classFormGroup = '',
  checked = false,
  disabled = false,
  onChange = () => { },
  validation = {},
  error = false,
  form = false,
}) {
  const classes = useStylesCheckboxUI();

  return (
    <FormGroup className={`${classes.checkbox_ui__group} ${classFormGroup}`}>
      <FormControlLabel
        className={`${classes.checkbox_ui__label} ${classFormControlLabel}`}
        control={(
          <Checkbox
            {...validation}
            onChange={form ? (e) => validation?.onChange(e) : () => onChange()}
            checked={checked}
            className={`${classes.checkbox_ui} ${checked ? 'ckeck' : ''} ${disabled ? 'disabled' : ''} hov ${error ? 'error' : ''}`}
            checkedIcon={(
              <IconUI
                name="icon_check"
                className={`${classes.checkbox_ui__checked} hover-check`}
              />
            )}
            icon={<div className={`${classes.checkbox_ui__no_checked} ${error ? 'error' : ''} hover-no-check`} />}
            disableRipple
            disabled={disabled}
          />
        )}
        label={(
          <div
            className={`${classes.checkbox_ui__label_text} ${classLabel}`}
            dangerouslySetInnerHTML={{ __html: textLabel }}
          />
        )}
      />
    </FormGroup>
  );
};

export default CheckboxUI;
